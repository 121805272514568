import React from 'react';
import {
  FormControl,
  Select,
  MenuItem,
  Button,
  Chip,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import styles from "../../../styles/exercise-styles.js";
import questions from './3-4-questions.js';

const options = questions.map(q => q.a);

class ExerciseThreeFour extends React.Component {
  constructor(props) {
    super(props); 
    const questionsState = {};
    questions.forEach(question => {
      questionsState[question.name] = {
        value: "", 
        wrong: false,
        corrected: false,
      };
    });
    this.state = {
      mistakes: 0,
      checked: false,
      ...questionsState,
    };
    this.handleChange = this.handleChange.bind(this);
    this.checkAnswers = this.checkAnswers.bind(this);
    this.clearAnswers = this.clearAnswers.bind(this);
    this.showOrHideAnswer = this.showOrHideAnswer.bind(this);

    this.classes = this.props.classes;
  }

  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    const updatedName = { ...this.state[name], value: value };
    this.setState(prevState => ({ 
      [name]: updatedName,
    }));
  }

  checkAnswers() {
    const toUpdate = { mistakes: 0, checked: true };
    // Go through and check each question
    questions.forEach(question => {
        // Correct until proven wrong
        toUpdate[question.name] = { ...this.state[question.name], wrong: false };
        // Check the question
        if (question.a !== this.state[question.name].value) {
            toUpdate.mistakes++;
            toUpdate[question.name] = { ...this.state[question.name], wrong: true }; 
        }
    });
    this.setState(toUpdate);
    this.props.reportProgress({ mistakes: toUpdate.mistakes, outof: questions.length });
  }

  clearAnswers() {
    const toSet = { 
      mistakes: 0, 
      checked: false,
    };
    questions.forEach(question => {
      toSet[question.name] = { value: "", wrong: false, corrected: false };
    });
    this.setState(toSet);
  }

  showOrHideAnswer(name) {
    const o = questions.find(e => e.name === name)
    const newInfo = { ...this.state[name], corrected: !this.state[name].corrected };
    if (this.state[name].corrected) {
      // Hide answer if button is clicked again
      newInfo.value = "";
    } else {
      newInfo.value = o.a;
    }
    this.setState({ [name]: newInfo });
  }

  render() {
    return (
      <div style={{ margin: "0 auto", maxWidth: "700px" }}>
        <h2>3.4. Choose the correct question word to complete the question.</h2>
        <p>You can only use each question word once.</p>
          {questions.map(question => (
            <div key={question.name}>
              <div className="question-container" style={{ direction: "rtl", justifyContent: "flex-start", margin: "1.5rem 0" }}>
                  <div style={{ marginLeft: "1rem", fontSize: "1.1rem" }}>
                      <span style={{ margin: "0 0.5rem" }}>{question.name}.</span> {question.qStart}
                  </div>
                  <FormControl error={this.state[question.name].wrong}>
                      <Select
                              value={this.state[question.name].value}
                              classes={{ root: this.classes.nastaliqInSelect }}
                              onChange={this.handleChange}
                              inputProps={{
                                  name: question.name,
                              }}
                          >
                          {options.map(o => (
                              <MenuItem 
                                  key={o}
                                  value={o}
                                  classes={{ root: this.classes.nastaliq }}
                              >
                                  {o}
                              </MenuItem>
                          ))}
                      </Select>
                  </FormControl>
                  <div style={{ marginRight: "0.5rem", marginLeft: "1rem", fontSize: "1.1rem" }}>
                      {question.qEnd} 
                  </div>
                  {this.state.checked && 
                      <Button style={{ color: "grey" }} onClick={() => this.showOrHideAnswer(question.name)}>
                          {this.state[question.name].corrected ? "Hide" : "Answer"}
                      </Button>
                  }
              </div>
            </div>
          ))}
          <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between", marginTop: "2rem" }}>
            <Button variant="contained" color="primary" onClick={this.checkAnswers} style={{ marginBottom: "1em" }}>
              {(this.state.checked && (this.state.mistakes !== 0)) ? "Recheck" : "Check"} Answers
            </Button>
            <div>
              {this.state.mistakes ? <Chip label={`${this.state.mistakes} mistakes`} color="secondary" style={{ marginBottom: "1em" }} /> : ''}  
              {(!this.state.mistakes && this.state.checked) && <Chip label={`All Correct!`} color="primary" />}
            </div>
            <Button varient="contained" color="primary" onClick={this.clearAnswers} style={{ marginBottom: "1em" }}>
              Clear Answers
            </Button>
          </div>
        </div>
    );
  }
}

export default withStyles(styles)(ExerciseThreeFour);

