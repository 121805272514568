import React from 'react';
/* eslint-disable no-unused-vars */
import { BrowserRouter as Router, withRouter, Route, Link } from "react-router-dom";
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
// import ExpandLess from '@material-ui/icons/ExpandLess';
// import ExpandMore from '@material-ui/icons/ExpandMore';


class AppDrawerNavItem extends React.Component {
  constructor(props) {
    super(props); 

    const matchingLink = this.props.chapter.exercises && this.props.chapter.exercises.find(ex => ex.link === this.props.currentPath);
    this.state = {
        expanded: (matchingLink || ((this.props.currentPath === "/") && (this.props.chapterIndex === 0))) ? true : false,
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    // this.props.history.push(this.props.chapter.exercises[0].link)
    this.setState(prevState => ({
        expanded: !prevState.expanded
    }));
  }

  render() {
    const { chapter, currentPath } = this.props;
    // const pathSplit = window.location.pathname.split('/');
    // const currentPath = "/" + pathSplit[pathSplit.length - 2] + "/";
    if (chapter.exercises && this.props.available) {
        return (
            <>
                <Link to={chapter.exercises[0].link} style={{ textDecoration: "none", color: "inherit" }} onClick={this.handleClick}>
                    <ListItem button disableTouchRipple>
                        <ListItemText primary={chapter.title} />
                    </ListItem>
                </Link>
                <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
                    {chapter.exercises && chapter.exercises.map(exercise => (
                        <Link to={exercise.link} style={{ textDecoration: "none", color: "inherit" }} key={exercise.title}>
                            <ListItem button disableTouchRipple onClick={this.props.closeDrawer} selected={currentPath === exercise.link}>
                                <ListItemText secondary={exercise.title} style={{ marginLeft: "0.75rem" }} />
                            </ListItem>
                        </Link>
                    ))}
                </Collapse>
            </>
        )
    } else {
        return (
            <Link to={chapter.link} style={{ textDecoration: "none", color: "inherit" }}>
                <ListItem button disableTouchRipple onClick={this.props.closeDrawer}>
                    <ListItemText primary={chapter.title} primaryTypographyProps={{ color: "textSecondary" }} />
                </ListItem>
            </Link>
        );
    }
  }
}

export default AppDrawerNavItem;
