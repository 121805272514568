import React from 'react';

// eslint-disable-next-line
import { BrowserRouter as Route, Link, withRouter } from "react-router-dom";
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

function ExerciseContainer(props) {
    const { next, previous } = props.exercise;
    const Exercise = props.exercise.component;
    return (
        <div style={{ 
            fontFamily: `'Noto Nastaliq Urdu Draft', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif`,
        }}>
            {props.firstExercise && <h1>{props.heading}</h1>}
            <Exercise reportProgress={() => null} />
            <BottomNavigation style={{ justifyContent: "space-between", marginBottom: "1rem", marginTop: "2rem" }} showLabels>
                {previous ? 
                    <BottomNavigationAction 
                        label="Previous" 
                        icon={<ArrowBackIosIcon />} 
                        onClick={() => { props.history.push(previous.link)}}
                    />
                :
                    <BottomNavigationAction></BottomNavigationAction>
                }
                {next ?
                    <BottomNavigationAction 
                        label="Next" 
                        icon={<ArrowForwardIosIcon />} 
                        onClick={() => { props.history.push(next.link)}} 
                    />
                :
                    <BottomNavigationAction></BottomNavigationAction>
                }
            </BottomNavigation>
        </div>
    )
}

export default withRouter(ExerciseContainer);