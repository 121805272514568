import React from "react";
import questions from "./27-4-questions.js";
import LongBlanksExercise from "../../../components/LongBlanksExercise.js";

export default function exTwentySevenFour(props) {
    return (
        <LongBlanksExercise 
            title="27.4. Translate"
            questions={questions}
            dir="ltr"
            seperateAnswerLine
            reportProgress={props.reportProgress}
        />
    );
}
