export default [
    {
        name: "a",
        q: "آپ کو یہ کتاب پڑھنے میں کتنا وقت لگے گا؟",
        a: [""],
    },
    {
        name: "b",
        q: "مجھے یو نیورسٹی جانے میں دس منٹ لگتے ہیں۔",
        a: [""],
    },
    {
        name: "c",
        q: "کھانا پکانے میں بہت وقت لگتی ہے۔",
        a: ["کھانا پکانے میں بہت وقت لگتا ہے۔"],
    },
    {
        name: "d",
        q: "دفتر جانے میں دیر لگیں گے۔",
        a: ["دفتر جانے میں دیر لگے گی۔"],
    },
    {
        name: "e",
        q: "گھر صاف کرنے میں زیادہ وقت لگے گا۔",
        a: [""],
    },
    {
        name: "f",
        q: "بس آنے میں آدھا گھنٹہ  لگ جایٔ گی۔",
        a: ["بس آنے میں آدھا گھنٹہ لگ جائے گا۔"],
    },
];
