import React from "react";
import questions from "./2-3-questions.js";
import MiddleBlankExercise from "../../../components/MiddleBlankExercise";

export default function(props) {
    return (
        <MiddleBlankExercise 
            title="2.3. Complete the sentences by using the correct singular/plural form of the words given."
            questions={questions}
            reportProgress={props.reportProgress}
        />
    );
}