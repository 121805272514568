import ache from "../../../images/ex27-1/ache.png";
import fan from "../../../images/ex27-1/fan.png";
import hungry from "../../../images/ex27-1/hungry.jpg";
import shiver from "../../../images/ex27-1/shiver.png";
import sun from "../../../images/ex27-1/sun.png";
import thirst from "../../../images/ex27-1/thirst.png";

export default [
    {
        name: "a",
        a: ["اس کو گرمی لگ رہی ہے۔"],
        img: [
            { src: sun, alt: "Standing under the sun, hot" },
        ],
    },
    {
        name: "b",
        a: ["اس کو بھوک لگ رہی ہے۔"],
        img: [
            { src: ache, alt: "Someone holding their stomach" },
        ],
    },
    {
        name: "c",
        a: ["اس کو ڈر لگ رہا ہے۔"],
        img: [
            { src: hungry, alt: "A hungry boy" },
        ],
    },
    {
        name: "d",
        a: ["اس کو ہوا لگ رہی ہے۔"],
        img: [
            { src: fan, alt: "A girl sitting by a fan" },
        ],
    },
    {
        name: "e",
        a: ["اس کو سردی لگ رہی ہے۔"],
        img: [
            { src: shiver, alt: "A boy shivering" },
        ],
    },
    {
        name: "f",
        a: ["اس کو پیاس لگ رہی ہے۔"],
        img: [
            { src: thirst, alt: "A thirsty boy" },
        ],
    },
];
