const questions = [
    {
        name: "a",
        q: "وہ کرسیاں",
        a: ["plural"],
    },
    {
        name: "b",
        q: "یہ گیند",
        a: ["singular"],
    },
    {
        name: "c",
        q: "ٓآم میٹھا",
        a: ["singular"],
    },
    {
        name: "d",
        q: "یہ میرا غبارہ",
        a: ["singular"],
    },
    {
        name: "e",
        q: "باغ میں پھول",
        a: ["plural", "singular"],
    },
    {
        name: "f",
        q: "ٹوکری میں دو آم",
        a: ["plural"],
    },
    {
        name: "g",
        q: "وہ ابو",
        a: ["plural"],
    },
    {
        name: "h",
        q: "وہ  میری کتابیں",
        a: ["plural"],
    },
];

export default questions;