import React from "react";
import questions from "./8-4-questions.js";
import LongBlanksExercise from "../../../components/LongBlanksExercise.js";

export default function exEightFour(props) {
    return (
        <LongBlanksExercise 
            title="8.4. Describe Actions"
            subText="Describe what the people in each picture are doing"
            questions={questions}
            answerEnding="statement"
            imageQuestions
            reportProgress={props.reportProgress}
        />
    );
}
