const questions = [
    {
        name: "a",
        q: "یہ ایک دکان ہے۔",
        a: "singular",
        given: true,
    },
    {
        name: "b",
        q: "یہ دکانیں ہیں۔",
        a: "plural",
    }, 
    {
        name: "c",
        q: "وہ کپڑا ہے۔",
        a: "singular",
    },
    {
        name: "d",
        q: "وہ کپڑے ہیں۔",
        a: "plural",
    },
    {
        name: "e",
        q: "یہ ایک آدمی ہے۔",
        a: "singular",
    },
    {
        name: "f",
        q: "یہ آدمی ہیں۔",
        a: "plural",
    },
    {
        name: "g",
        q: "یہ ایک بچہ ہے۔",
        a: "singular",
    },
    {
        name: "h",
        q: "وہ سات بچے ہیں۔",
        a: "plural",
    },
    {
        name: "i",
        q: "یہ ایک عورت ہے۔",
        a: "singular",
    },
    {
        name: "j",
        q: "وہ عورتیں ہیں۔",
        a: "plural",
    },
    {
        name: "k",
        q: "یہ ایک زبان ہے۔",
        a: "singular",
    },
    {
        name: "l",
        q: "یہ زبانیں ہیں۔",
        a: "plural",
    },
    {
        name: "m",
        q: "یہ استاد ہیں۔",
        a: "plural",
    },
    {
        name: "n",
        q: "وہ استاد ہیں۔",
        a: "plural",
    },
    {
        name: "o",
        q: "وہ ایک لڑکا ہے۔",
        a: "singular",
    },
    {
        name: "p",
        q: "وہ لڑکے ہیں۔",
        a: "plural",
    },
];


export default questions;