import flower from '../../../images/flower.png';
import teacup from '../../../images/teacup.png';
import bananas from '../../../images/bananas.png';
import icecream from '../../../images/icecream.png';
import sweater from '../../../images/sweater.png';

import khoobsoorat from '../../../images/khoobsoorat.png';
import garm from '../../../images/garm.png';
import meettha from '../../../images/meettha.png';
import taaza from '../../../images/taaza.png';
import puraanaa from '../../../images/puraanaa.png';

const imageRows = [
    {src: flower, w:143, h:210, word: [3]}, 
    {src: teacup, w:253, h:200, word: [1]},
    {src: bananas, w:194, h:200, word: [0]}, 
    {src: icecream, w:110, h:220, word: [2]},
    {src: sweater, w:256, h:190, word: [4]}, 
];
  
const wordRows = [
    {src: taaza, w: 105, h: 75},
    {src: garm, w: 104, h: 125}, 
    {src: meettha, w: 91, h: 80},
    {src: khoobsoorat, w:184, h: 75},   
    {src: puraanaa, w: 95, h: 85}, 
];

export { imageRows, wordRows }; 
