import React from "react";
import questions from "./4-1-questions.js";
import LongBlanksExercise from "../../../components/LongBlanksExercise.js";

function exFourOne(props) {
    return (
        <LongBlanksExercise 
            title="4.1. Change the sentences into plural."
            subText="Remember to change the ending of the adjectives accordingly."
            questions={questions}
            dir="rtl" 
            answerEnding="statement"
            reportProgress={props.reportProgress}
        />
    );
}

export default exFourOne;