import React, { useEffect } from 'react';
import { compose } from 'redux';
import { MuiThemeProvider } from '@material-ui/core/styles';
import theme from './theme.js';
// eslint-disable-next-line
import { BrowserRouter as Router, withRouter, Route, Redirect, Link, Switch } from "react-router-dom";
import NotFound from "./components/NotFound.js";
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import AppBar from '@material-ui/core/AppBar';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import AppDrawerNavItem from './components/AppDrawerNavItem.js';
import CssBaseline from '@material-ui/core/CssBaseline';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ExerciseContainter from './components/ExerciseContainer.js';
import MenuIcon from '@material-ui/icons/Menu';
import chapters from "./content/chapters.js";
import MailOutline from '@material-ui/icons/MailOutline';
import WhatsApp from '@material-ui/icons/WhatsApp';
import Facebook from '@material-ui/icons/Facebook';
import Language from '@material-ui/icons/Language';
import Media from "react-media";
import PrivacyPolicy from './PrivacyPolicy.js';

const drawerWidth = 250;

const title = "Urdu Online Activities";

const styles = {
  root: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: "#ffffff",
  },
  title: {
    color: "white",
    flexGrow: 1,
  },
  subTitle: {
    color: "white",
    fontSize: "smaller",
    flexGrow: 1,
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    marginLeft: drawerWidth,
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
    backgroundColor: "#F05858", 
  },
  menuButton: {
    marginRight: 8,
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexShrink: "1",
    flexGrow: "1",
    height: "100%",
    minWidth: "60%",
    padding: "0 1rem",
  },
  listItemText: {
    textDecoration: "none",
  },
};

const queries = {
  mobile: "(max-width: 699px)",
  mid: "(min-width: 700px) and (max-width: 1199px)",
  desktop: "(min-width: 1200px)",
};

function App(props) {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  function handleDrawerToggle() {
    setMobileOpen(!mobileOpen);
  }
  const { classes, container } = props;

  function closeDrawer() {
    setMobileOpen(false);
  }

  useEffect(() => {
    if (props.location.pathname === "/") {
      props.history.replace("/ex1-1");
    }
  }, [props.location, props.history]);

  const drawer = (
    <div>
      <div className={classes.toolbar}>
        <ListItem>
          <a href="https://www.urdulearner.com" className="unstyled-link">
            <ListItemText primary={title} secondary={"urdulearner.com"} variant="h6" />
          </a>
        </ListItem>
      </div>
      <Divider />
      <List>
        <a href="https://urdulearner.com/practiceurdu-faq/" style={{ textDecoration: "none" }}>
          <ListItem button disableTouchRipple>
            <ListItemText primary="Help/Info" />
          </ListItem>
        </a>
        {chapters.map((chapter, i) => (
          <AppDrawerNavItem
            available={true}
            chapterIndex={i}
            key={chapter.title}
            chapter={chapter}
            closeDrawer={closeDrawer}
            currentPath={props.location.pathname} 
           />
          ))}
      </List>
    </div>
  );
  return (
    <MuiThemeProvider theme={theme}>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="fixed" className={classes.appBar}>
          <Media queries={queries}>
            {matches => (
              <Toolbar>
                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  className={classes.menuButton}
                >
                  <MenuIcon />
                </IconButton>
                {matches.desktop &&
                  <Typography className={classes.title} variant="h6" noWrap>
                    <Link to="/" className="unstyled-link">Urdu Online Activities</Link>
                  </Typography>
                }
                {matches.mid &&
                  <Typography className={classes.title} variant="h6" noWrap>
                    <Link to="/" className="unstyled-link">Urdu Online Activities by Zubaan Traning</Link>
                  </Typography>                 
                }
                {matches.mobile &&
                  <Typography className={classes.subTitle} varient="p" noWrap>
                    Activites by <a href="https://zubaantraining.net/" className="unstyled-link">Zubaan Traning</a>
                  </Typography>
                }
                {matches.desktop &&
                  <Typography className={classes.subTitle} variant="h6" noWrap>
                    <a href="https://zubaantraining.net/" className="unstyled-link">
                      Call Zubaan Training Today!
                    </a>
                    <a href="tel:+92-310-9777720" className="unstyled-link">
                      {` `}+92-310-9777720 |
                    </a>
                    <a href="mailto:info@zubaantraining.net" className="unstyled-link">
                      {` `}info@zubaantraining.net
                    </a>
                  </Typography>
                }
                <IconButton color="inherit" edge="end">
                  <a href="https://www.facebook.com/zubaantraining" className="unstyled-link">
                    <Facebook />
                  </a>
                </IconButton>
                {(matches.desktop || matches.mid) && 
                  <IconButton color="inherit">
                    <a href="https://zubaantraining.net/" className="unstyled-link">
                      <Language />
                    </a>
                  </IconButton>
                }
                {(matches.desktop || matches.mid) && 
                  <IconButton color="inherit">
                    <a href="mailto:info@urdulearner.com" className="unstyled-link">
                      <MailOutline />
                    </a>
                  </IconButton>
                }
                <IconButton color="inherit">
                  <a href="https://web.whatsapp.com/send?phone=923109777720&text=" className="unstyled-link">
                    <WhatsApp />
                  </a>
                </IconButton>
              </Toolbar>
            )}
          </Media>
        </AppBar>
        <nav className={classes.drawer}>
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Hidden smUp implementation="css">
            <Drawer
              container={container}
              variant="temporary"
              anchor={theme.direction === 'rtl' ? 'right' : 'left'}
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Drawer
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent"
              open
            >
              {drawer}
            </Drawer>
          </Hidden>
        </nav>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Switch>
            <Route path="/" exact render={() => (
              <ExerciseContainter 
                exercise={chapters[0].exercises[0]} 
                heading={chapters[0].heading} 
                toggleDrawer={handleDrawerToggle}
                first={true}
              />
            )} />
            <Route path="/privacy-policy" component={PrivacyPolicy} />
            {chapters.map(chapter => {
              return [
                <Route key={chapter.title} path={chapter.link}>
                  <Redirect to={chapter.exercises[0].link} />
                </Route>,
                ...chapter.exercises.map((exercise, index) => (
                  <Route key={exercise.link} path={exercise.link} render={() => (
                    <ExerciseContainter 
                      exercise={exercise} 
                      heading={chapter.heading} 
                      toggleDrawer={handleDrawerToggle}
                      first={index === 0}
                    />
                  )} />
                )), 
              ];
            })}
            <Route component={NotFound} />
          </Switch>
        </main>
      </div>
    </MuiThemeProvider>
  );
}

export default compose(
  withRouter,
  withStyles(styles),
)(App);
