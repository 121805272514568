export default [
    {
        name: "a",
        q: "یہ طالب علم کی کتاب ہے۔",
        a: ["یہ طالب علم کی کتابیں ہیں۔"],
        given: true,
    },
    {
        name: "b",
        q: "یہ طالب علم کا کمراہے۔",
        a: ["یہ طالب علم کے کمرے ہیں۔"],
    },
    {
        name: "c",
        q: "یہ طالب علم کا جوتا ہے۔",
        a: ["یہ طالب علم کے جوتے ہیں۔"],
    },
    {
        name: "d",
        q: "یہ طالب علم کی میز ہے۔",
        a: ["یہ طالب علم کی میزیں ہیں۔"],
    },
    {
        name: "e",
        q: "یہ طالب علم کا قلم ہے۔",
        a: ["یہ طالب علم کے قلم ہیں۔"],
    },
];
