const styles = {
  nastaliq: {
    fontFamily: "Noto Nastaliq Urdu Draft",
    fontSize: "1.1rem",
    direction: "rtl",
  },
  nastaliqInSelect: {
    fontFamily: "Noto Nastaliq Urdu Draft",
    fontSize: "1.1rem",
    lineHeight: "unset",
    minWidth: "4rem",
  },
  shortInput: {
    fontFamily: "Noto Nastaliq Urdu Draft",
    lineHeight: "unset",
    padding: "0",
    fontSize: "1.1rem",
    maxWidth: "12rem",
  },
  veryShortInput: {
    fontFamily: "Noto Nastaliq Urdu Draft",
    lineHeight: "unset",
    padding: "0",
    fontSize: "1.1rem",
    maxWidth: "3rem",
  },
  shortCorrectedInput: {
    fontFamily: "Noto Nastaliq Urdu Draft",
    lineHeight: "unset",
    color: "grey",
    padding: "0",
    fontSize: "1.1rem",
    maxWidth: "12rem",
  },
  veryShortCorrectedInput: {
    fontFamily: "Noto Nastaliq Urdu Draft",
    lineHeight: "unset",
    color: "grey",
    padding: "0",
    fontSize: "1.1rem",
    maxWidth: "3rem",
    margin: "0"
  },
  input: {
    fontFamily: "Noto Nastaliq Urdu Draft",
    lineHeight: "unset",
    padding: "0",
    fontSize: "1.1rem",
  },
  correctedInput: {
    fontFamily: "Noto Nastaliq Urdu Draft",
    lineHeight: "unset",
    color: "grey",
    padding: "0",
    fontSize: "1.1rem",
  },
  englishInput: {
    direction: "ltr",
    fontSize: "1.1rem",
  },
  correctedEnglishInput: {
    direction: "ltr",
    color: "grey",
    fontSize: "1.1rem",
  },
};

export default styles;