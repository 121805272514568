export default [
    {
        name: "a",
        q: "You should go to Islamabad and see the Faisal Mosque.",
        a: ["آپ کو اسلام آباد جا کر فیصل مسجد دیکھنی چاہیے۔"],
    },
    {
        name: "b",
        q: "She sat and read the newspaper in the bus.",
        a: ["اس نے بس میں بیٹھ کر اخبار پڑھا۔"],
    },
    {
        name: "c",
        q: "They washed their hands and begun to eat.",
        a: ["انہوں نے اپنے ہاتھ دھوکر کھانا شروع کیا۔"],
    },
    {
        name: "d",
        q: "The farmer went and worked in the field.",
        a: ["کسان نے کھیت میں جا کر کام کیا۔"],
    },
    {
        name: "e",
        q: "The policeman ran and caught the thief.",
        a: ["پولیس والے نے بھاگ کر چور کو پکڑا۔"],
    },
    {
        name: "f",
        q: "She came home and began to cook dinner.",
        a: ["اس نے گھر آ کر رات کا کھانا پکانا شروع کیا۔"],
    },
    {
        name: "g",
        q: "After finishing their work, they will watch a movie.",
        a: ["وہ اپنا کام ختم کر کے فلم دیکھیں گے۔"],
    },
    {
        name: "h",
        q: "Having talked with his friends, he went home.",
        a: ["اپنے دوست سے بات کر کے وہ گھر گیا۔"],
    },
]
