export default [
    {
        name: "a",
        q: "بچہ",
        a: ["قالین کے اوپر بچہ ہے۔", "بچہ قالین کے اوپر ہے۔"],
    },
    {
        name: "b",
        q: "تصویریں",
        a: ["دیوار پر تصویریں ہیں۔", "تصویریں دیوار پر ہیں۔"],
    },
    {
        name: "c",
        q: "گلدان",
        a: ["گلدان فرش پر ہے۔", "فرش پر گلدان ہے۔"],
    },
    {
        name: "d",
        q: "عورت",
        a: ["عورت دروازے کے پیچھے ہے۔", "دروازے کے پیچھے عورت ہے۔"],
    },
]