import React from "react";
import questions from "./8-2-questions.js";
import LongBlanksExercise from "../../../components/LongBlanksExercise.js";

export default function exEightTwo(props) {
    return (
        <LongBlanksExercise 
            title="8.2. Do you live here?"
            subText="Answer the following questions."
            questions={questions}
            answerEnding="statement"
            dir="rtl"
            reportProgress={props.reportProgress}
        />
    );
}

