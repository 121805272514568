import React from "react";
import questions from "./24-1-questions.js";
import MiddleBlankExercise from "../../../components/MiddleBlankExercise";

export default function(props) {
    return (
        <MiddleBlankExercise 
            title="4.3. Complete the following sentences"
            longerBlanks
            questions={questions}
            reportProgress={props.reportProgress}
        />
    );
}
