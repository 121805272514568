import React from "react";
import questions from "./24-3-questions.js";
import LongBlanksExercise from "../../../components/LongBlanksExercise.js";

function exTwentyFourThree(props) {
    return (
        <LongBlanksExercise 
            title="24.3. Translate"
            questions={questions}
            dir="ltr"
            seperateAnswerLine
            reportProgress={props.reportProgress}
        />
    );
}

export default exTwentyFourThree;
