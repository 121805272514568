import React from "react";
import questions from "./2-2-questions.js";
import RadioExercise from "../../../components/RadioExercise.js";

function exTwoTwo(props) {
    return (
        <RadioExercise 
            title="2.2. Select Whether Each Sentence is Singular or Plural"
            questions={questions}
            options={[
              {value: "singular", label: "Singular"}, 
              {value: "plural", label: "Plural"},
            ]} 
            reportProgress={props.reportProgress}
        />
    );
}

export default exTwoTwo;