export default [
    {
        name: "a",
        q: "چھوٹا گھر / میں",
        a: ["یہ میرا چھوٹا گھر ہے۔"],
        given: true,
    },
    {
        name: "b",
        q: "اچھے کپڑے / آپ",
        a: ["یہ آپ کے اچھے کپڑے ہیں۔"],
    },
    {
        name: "c",
        q: "بڑی کرسی / وہ",
        a: ["یہ اس کی بڑی کرسی ہے۔"],
    },
    {
        name: "d",
        q: "مہنگے جوتے / یہ",
        a: ["یہ اس کے مہنگے جوتے ہیں۔"],
    },
    {
        name: "e",
        q: "اردو کی کتاب / ہم",
        a: ["یہ ہماری اُردو کی کتاب ہے۔", "یہ ہماری اردو کی کتاب ہے۔"],
    },
    {
        name: "f",
        q: "سستے قلم / تو",
        a: ["یہ تمہارے سستے قلم ہیں۔"],
    },
];
