export default [
    {
        name: "a",
        q: "میں / پاکستانی کھانا ",
        a: ["مجھے پاکستانی کھانا اچھا لگتا ہے۔"],
        given: true,
    },
    {
        name: "b",
        q: "وہ / یہ کپڑے",
        a: ["اس کو یہ کپڑے اچھے لگتے ہیں۔"],
    },
    {
        name: "c",
        q: "ہم / نئے جوتے",
        a: ["ہمیں نئے جوتے اچھے لگتے ہیں۔"],
    },
    {
        name: "d",
        q: "یہ / وہ لڑکی",
        a: ["اس کو یہ لڑکی اچھی لگتی ہے۔"],
    },
    {
        name: "e",
        q: "آپ / اردو پڑھنا",
        a: ["آپ کو اردو پڑھنا اچھا لگتا ہے۔"],
    },
    {
        name: "f",
        q: "میں / موسیقی سننا",
        a: ["مجھے موسیقی سننا اچھا لگتا ہے۔"],
    },
];
