export default [
    {
        name: "a",
        q: "have breakfast/go to office",
        a: [
            "میں ناشتہ کرکے دفتر گیا۔",
            "میں ناشتہ کرکے دفتر گئی۔",
        ],
    },
    {
        name: "b",
        q: "arrive at the office/have meeting with boss",
        a: [
            "دفتر پہنچ کر باس کے ساتھ میٹنگ کی۔",
        ],
    },
    {
        name: "c",
        q: "having a meeting/eating lunch and resting",
        a: [
            "میٹنگ کر کے دوپہر کا کھانا کھایا اور پھر تھوڑا آرام کیا۔",
        ],
    },
    {
        name: "d",
        q: "finish all office work/arrive late at home",
        a: [
            "دفتر کا سارا کام ختم کر کے میں دیر سے گھر گئی۔",
            "دفتر کا سارا کام ختم کر کے میں دیر سے گھر گیا۔",
        ],
    },
    {
        name: "e",
        q: "eating dinner/go to bed early",
        a: [
            "رات کا کھانا کھا کر جلدی سو گیا۔",
            "رات کا کھانا کھا کر جلدی سو گئی۔",
        ],
    },
];
