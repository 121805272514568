import React from "react";
import questions from "./6-2-questions.js";
import LongBlanksExercise from "../../../components/LongBlanksExercise.js";

function exSixTwo(props) {
    return (
        <LongBlanksExercise 
            title="6.2. Where are the books? What is under the chair?"
            subText="Make sentences using the locations given below."
            questions={questions}
            answerEnding="statement"
            reportProgress={props.reportProgress}
        />
    );
}

export default exSixTwo;