import { createMuiTheme } from '@material-ui/core/styles';
// Was blue, but am using the same blue as the site
// import blue from '@material-ui/core/colors/blue';

export default createMuiTheme({
    typography: {
        useNextVariants: true,
    },
    spacing: 4,
    palette: {
        primary: {
            main: "#1C314C", // "#203856",// ,
        },
        error: {
            main: "#F50057",
        }
    },
    tonalOffset: 0.5,
});