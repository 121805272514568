export default [
    {
        name: "a",
        q: "میز کے اوپر قالین ہے",
        a: "n",
    },
    {
        name: "b",
        q: "گلدان میز پر ہے",
        a: "y",
    },
    {
        name: "c",
        q: "فرش پر صوفہ ہے",
        a: "y",
    },
    {
        name: "d",
        q: "قالین میز کے نیچے ہے",
        a: "y",
    },
    {
        name: "e",
        q: "میز صوفے کے پیچھے ہے",
        a: "n",
    },
    {
        name: "f",
        q: "پودے صوفے کے پیچھے ہیں",
        a: "y",
    },
    {
        name: "g",
        q: "پودے دیوار کے آگے ہیں",
        a: "y",
    },
    {
        name: "h",
        q: "تصویر الماری کے اوپر ہے",
        a: "n",
    },
    {
        name: "i",
        q: "الماری دیوار کے آگے ہے",
        a: "y",
    },
    {
        name: "j",
        q: "دیوار پر تصویر ہے",
        a: "y",
    },
];