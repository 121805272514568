const questions = [
    {
        "name": "a",
        "q": "کیا آپ خوش ہیں؟",
        "a": [
            "جی ہاں میں خوش ہوں۔",
            "جی نہیں میں خوش نہیں ہوں۔",
            "نہیں میں خوش نہیں ہوں۔",
        ],
        "dir": "rtl",
        "given": true,
    },
    {
        "name": "b",
        "q": "کیا آپ طالب علم ہیں؟",
        "a": [
            "جی ہاں میں طالب علم ہوں۔",
            "جی نہیں میں طالب علم نہیں ہوں۔",
            "نہیں میں طالب علم نہیں ہوں۔",
        ],
        "dir": "rtl",
    },
    {
        "name": "c",
        "q": "کیا آپ شادی شده ہیں؟",
        "a": [
            "جی ہاں میں شادی شده ہوں۔",
            "جی نہیں میں شادی شده نہیں ہوں۔",
            "نہیں میں شادی شده نہیں ہوں۔",
        ],
        "dir": "rtl",
    },
    {
        "name": "d",
        "q": "کیا آپ امريکی ہیں؟",
        "a": [
            "جی ہاں میں امریکی ہوں۔",
            "جی نہیں میں امریکی نہیں ہوں۔",
            "نہیں میں امریکی نہیں ہوں۔",
        ],
        "dir": "rtl",
    },
    {
        "name": "e",
        "q": "کیا آپ پاکستانی ہیں؟",
        "a": [
            "جی ہاں میں پاکستانی ہوں۔",
            "جی نہیں میں پاکستانی نہیں ہوں۔",
            "نہیں میں پاکستانی نہیں ہوں۔",
        ],
        "dir": "rtl",
    },
    {
        "name": "f",
        "q": "کیا آپ استاد ہیں؟",
        "a": [
            "جی ہاں میں استاد ہوں۔",
            "جی نہیں میں استاد نہیں ہوں۔",
            "نہیں میں استاد نہیں ہوں۔",
        ],
        "dir": "rtl",
    },
];

export default questions;