export default [
    {
        name: "a",
        q: "گھر جانا",
        a: ["میں گھر جارہا ہوں۔", "میں گھر جارہی ہوں۔"],
        given: true,
    },
    {
        name: "b",
        q: "پھل کھانا",
        a: ["میں پھل کھا رہا ہوں۔", "میں پھل کھا رہی ہوں۔"],
    },
    {
        name: "c",
        q: "انگریزی بولنا",
        a: ["میں انگریزی بول رہا ہوں۔", "میں انگریزی بول رہی ہوں۔"],
    },
    {
        name: "d",
        q: "دودھ پینا",
        a: ["میں دودھ پی رہا ہوں۔", "میں دودھ پی رہی ہوں۔"],
    },
    {
        name: "e",
        q: "کتابیں پڑھنا",
        a: ["میں کتابیں پڑھ رہا ہوں۔", "میں کتابیں پڑھ رہی ہوں۔"],
    },
    {
        name: "f",
        q: "سوال پوچھنا",
        a: ["میں سوال پوچھ رہا ہوں۔", "میں سوال پوچھ رہی ہوں۔"],
    },
    {
        name: "g",
        q: "اردو پڑھنا",
        a: ["میں اردو پڑھ رہا ہوں۔", "میں اردو پڑھ رہی ہوں۔"],
    },
];
