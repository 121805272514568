const questions = [
    {
        name: "a",
        q: "یہ کتاب اچھی ہے۔",
        a: ["کیا یہ کتاب اچھی ہے؟"],
        given: true,
        dir: "rtl",
    },
    {
        name: "b",
        q: "وہ ہوٹل اچھا ہے۔",
        a: ["کیا وہ ہوٹل اچھا ہے؟"],
        dir: "rtl",
    },
    {
        name: "c",
        q: "یہ میز چھوٹی ہے۔",
        a: ["کیا یہ میز چھوٹی ہے؟"],
        dir: "rtl",
    },
    {
        name: "d",
        q: "یہ شہر خوبصورت ہے۔",
        a: ["کیا یہ شہر خوبصورت ہے؟"],
        dir: "rtl",
    },
    {
        name: "e",
        q: "یہ پھل تازہ ہیں۔",
        a: ["کیا یہ پھل تازے ہیں؟"],
        dir: "rtl",
    },
];

export default questions;
