import React from "react";
import questions from "./10-4-questions.js";
import LongBlanksExercise from "../../../components/LongBlanksExercise.js";

export default function exTenFour(props) {
    return (
        <LongBlanksExercise 
            title="10.4. Translate."
            questions={questions}
            reportProgress={props.reportProgress}
        />
    );
}
