import React from "react";
import questions from "./24-2-questions.js";
import LongBlanksExercise from "../../../components/LongBlanksExercise.js";

export default function exTwentyFourTwo(props) {
    return (
        <LongBlanksExercise 
            title="24.2. Compare"
            subText="Look at each pair of pictures and write a sentence comparing them"
            questions={questions}
            answerEnding="statement"
            imageQuestions
            reportProgress={props.reportProgress}
        />
    );
}
