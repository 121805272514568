const questions = [
    {
        name: "a",
        q: "Chair",
        a: "تین کرسیاں ہیں۔",
        given: true,
    },
    {
        name: "b",
        q: "People",
        a: "چار لوگ ہیں۔",
    },
    {
        name: "c",
        q: "Table",
        a: "دو میزیں ہیں۔",
    },
    {
        name: "d",
        q: "Plant",
        a: "دو پودے ہیں۔",
    },
    {
        name: "f",
        q: "Picture",
        a: "آٹھ تصویریں ہیں۔",
    },
    {
        name: "g",
        q: "Window",
        a: "ایک کھڑکی ہے۔",
    },
    {
        name: "h",
        q: "Cup",
        a: "ایک کپ ہے۔",
    },
    {
        name: "j",
        q: "Child",
        a: "دو بچے ہیں۔",
    },
    {
        name: "k",
        q: "Carpet",
        a: "ایک قالین ہے۔",
    },
    {
        name: "l",
        q: "Cupboard",
        a: "ایک الماری ہے۔",
    },
];

export default questions;
