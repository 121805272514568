import React from "react";
import questions from "./3-2-questions.js";
import LongBlanksExercise from "../../../components/LongBlanksExercise.js";

export default function(props) {
    return (
        <LongBlanksExercise 
            title="3.2. Translate."
            questions={questions} 
            answerEnding="question"
            reportProgress={props.reportProgress}
        />
    );
}
