const questions = [
    {
        name: "a",
        qStart: "وہ ایک",
        qEnd: "ہے",
        qParenth: "boy",
        a: ["لڑکا"],
    },
    {
        name: "b",
        qStart: "یہ",
        qEnd: "ہے",
        qParenth: "sister",
        a: ["بہن"],
    },
    {
        name: "c",
        qStart: "وه",
        qEnd: "ہیں",
        qParenth: "boys",
        a: ["لڑکے"],
    },
    {
        name: "d",
        qStart: "یہ",
        qEnd: "ہیں", 
        qParenth: "sisters",
        a: ["بہنیں"],
    },
    {
        name: "e",
        qStart: "یہ ایک",
        qEnd: "ہے", 
        qParenth: "rickshaw",
        a: ["رکشہ"],
    },
    {
        name: "f",
        qStart: "یہ ایک",
        qEnd: "ہے", 
        qParenth: "table",
        a: ["میز"],
    },
    {
        name: "g",
        qStart: "یہ",
        qEnd: "ہیں",
        qParenth: "rickshaws",
        a: ["رکشے"],
    },
    {
        name: "h",
        qStart: "یہ",
        qEnd: "ہیں",
        qParenth: "tables",
        a: ["میزیں"],
    },
]

export default questions;