import React from "react";
import questions from "./12-4-questions.js";
import LongBlanksExercise from "../../../components/LongBlanksExercise.js";

export default function exTwelveFour(props) {
    return (
        <LongBlanksExercise 
            title="What do you know?"
            subText="Re-write sentences using the verb ana آنا"
            questions={questions}
            dir="rtl"
            reportProgress={props.reportProgress}
        />
    );
}
