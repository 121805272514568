export default [
    {
        name: "a",
        q: "Father/newspaper",
        a: ["باپ اخبار پڑھے گا۔"],
    },
    {
        name: "b",
        q: "Mother/warm sweater",
        a: ["ماں گرم سویٹر بُنے گی۔"],
    },
    {
        name: "c",
        q: "Younger daughter/picture",
        a: ["چھوٹی بیٹی تصویر بنائے گی۔"],
    },
    {
        name: "d",
        q: "Older son & older daughter/coffee",
        a: ["بڑا بیٹا اور بڑی بیٹی کافی پیئں گے۔"],
    },
    {
        name: "e",
        q: "Younger brother/new toys",
        a: ["چھوٹا بھائی نئے کھلونوں سے کھیلے گا۔"],
    },
];
