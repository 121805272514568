import cookingEggs from "../../../images/ex8-4/cooking-eggs.png";
import eating from "../../../images/ex8-4/eating.png";
import jobInterview from "../../../images/ex8-4/job-interview.png";
import listeningToHeadphones from "../../../images/ex8-4/listening-to-headphones.png";
import playingSoccer from "../../../images/ex8-4/playing-soccer.png";
import readingBook from "../../../images/ex8-4/reading-book.png";
import shopping from "../../../images/ex8-4/shopping.png";
import showering from "../../../images/ex8-4/showering.png";
import sleeping from "../../../images/ex8-4/sleeping.png";
import studying from "../../../images/ex8-4/studying.png";
import talkingOnPhone from "../../../images/ex8-4/talking-on-phone.png";
import teachingClass from "../../../images/ex8-4/teaching-class.png";
import walkingWithUmbrella from "../../../images/ex8-4/walking-with-umbrella.png";
import watchingTv from "../../../images/ex8-4/watching-tv.jpg";

export default [
    {
        name: "a",
        a: ["وہ ٹی وی دیکھتا ہے۔"],
        img: [{ src: watchingTv, alt: "A man watching TV" }],
        given: true,
    },
    {
        name: "b",
        a: ["وہ ناشتہ بناتا ہے۔"],
        img: [{ src: cookingEggs, alt: "A man cooking eggs" }],
    },
    {
        name: "c",
        a: ["وہ کھانا کھاتا ہے۔"],
        img: [{ src: eating, alt: "A man eating food" }],
    },
    {
        name: "d",
        a: ["وہ کتاب پڑھتی ہے۔"],
        img: [{ src: readingBook, alt: "A woman reading a book" }],
    },
    {
        name: "e",
        a: ["وہ موسیقی سنتا ہے۔"],
        img: [{ src: listeningToHeadphones, alt: "A man listening to music" }],
    },
    {
        name: "f",
        a: ["وہ فٹ بال کھیلتا ہے۔"],
        img: [{ src: playingSoccer, alt: "A man playing soccer" }],
    },
    {
        name: "g",
        a: ["وہ خط لکھتا ہے۔"],
        img: [{ src: studying, alt: "A man writing a letter" }],
    },
    {
        name: "h",
        a: ["وہ نہاتی ہے۔"],
        img: [{ src: showering, alt: "A woman showering" }],
    },
    {
        name: "i",
        a: ["وہ خریدتی ہے۔"],
        img: [{ src: shopping, alt: "A woman shopping" }],
    },
    {
        name: "j",
        a: ["وہ فون پر بات کرتی ہے۔"],
        img: [{ src: talkingOnPhone, alt: "A woman talking on the phone" }],
    },
    {
        name: "k",
        a: ["وہ کاغذ دیتا ہے۔", "وہ میٹنگ کرتا ہے۔"],
        img: [{ src: jobInterview, alt: "A man in a meeting" }],
    },
    {
        name: "l",
        a: ["وہ چلتے ہیں۔"],
        img: [{ src: walkingWithUmbrella, alt: "People walking" }],
    },
    {
        name: "m",
        a: ["وہ سوتا ہے۔"],
        img: [{ src: sleeping, alt: "A man sleeping" }],
    },
    {
        name: "n",
        a: ["وہ پڑھاتا ہے۔", "وہ پڑھتے ہیں۔"],
        img: [{ src: teachingClass, alt: "A man teaching" }],
    }
];
