export default [
    {
        name: "a",
        q: "I am listening to Pakistani music. (male)",
        a: ["میں پاکستانی موسیقی سن رہی ہوں۔"],
    },
    {
        name: "b",
        q: "Are you reading the Urdu newspaper (polite form)?",
        a: ["کیا آپ اردو اخبار پڑھ رہے ہیں؟"],
    },
    {
        name: "c",
        q: "The student is buying books from this book store. (male)",
        a: [".طالب علم اس کتاب کی دکان سے کتابیں خرید رہا ہے"],
    },
    {
        name: "d",
        q: "We are not studying French. We are studying Urdu.",
        a: ["ہم فرانسیسی نہیں پڑھ رہے ہیں۔ ہم اردو پڑھ رہے ہیں۔"],
    },
    {
        name: "e",
        q: "She is asking us many questions.",
        a: ["وہ ہم سے بہت سوال پوچھ رہی ہے۔"],
    },
    {
        name: "f",
        q: "What are you doing here in my room (familiar form)?",
        a: ["تم یہاں میرے کمرے میں کیا کر رہی ہو؟"],
    },
    {
        name: "g",
        q: "Are you singing an Urdu song (intimate form - female)?",
        a: ["کیا تواردو گانا گارہی ہے؟"],
    },
    {
        name: "h",
        q: "She is not going home. She is going to the party.",
        a: ["وہ گھر نہیں جا رہی ہے۔ وہ دعوت میں جا رہی ہے۔"],
    },
    {
        name: "i",
        q: "They are speaking Urdu with that Pakistani man.",
        a: ["وہ اس پاکستانی آدمی سے اردو میں بات کر رہے ہیں۔"],
    },
    {
        name: "j",
        q: "Our teacher is telling us about Pakistan. (female)",
        a: [".ہماری استانی ہمیں پاکستان کے بارے میں بتا رہی ہیں"],
    },
];
