import React from "react";
import questions from "./11-4-questions.js";
import LongBlanksExercise from "../../../components/LongBlanksExercise.js";
import marketActivity from "../../../images/ex11-4/market-activity.png";

export default function exElevenFour(props) {
    return (
        <LongBlanksExercise 
            title="What are they doing?"
            subText="Look at the picture and describe what the different people are doing at the moment."
            image={{ src: marketActivity, alt: "A marketplace with people" }}
            questions={questions}
            reportProgress={props.reportProgress}
        />
    );
}
