import React from 'react';
import {
  FormControl,
  TextField,
  Button,
  Chip,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import styles from "../styles/exercise-styles.js";
import cleanUrdu from "../lib/clean-urdu.js";
import removePunctuation from "../lib/remove-punctuation.js";
import InputAdornment from '@material-ui/core/InputAdornment';

function compareUrdu(possibleAnswers, input) {
  const u = cleanUrdu(input.trim());

  let answerCorrect = false;
  possibleAnswers.forEach(a => {
    if (removePunctuation(a) === u) {
      answerCorrect = true;
    }; 
  });
  
  return answerCorrect;
}

class LongBlanksExercise extends React.Component {
  constructor(props) {
    super(props); 
    const questionsState = {};
    props.questions.forEach(question => {
      this[question.name + "Ref"] = React.createRef();
      questionsState[question.name] = { 
        wrong: false,
        corrected: false,
      };
    });
    this.state = {
      mistakes: 0,
      checked: false,
      ...questionsState,
    };
    this.handleChange = this.handleChange.bind(this);
    this.checkAnswers = this.checkAnswers.bind(this);
    this.clearAnswers = this.clearAnswers.bind(this);
    this.showOrHideAnswer = this.showOrHideAnswer.bind(this);

    this.classes = this.props.classes;
  }

  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({ [name]: value });
  }

  checkAnswers() {
    const toUpdate = { mistakes: 0, checked: true };
    // Go through and check each question
    this.props.questions.forEach(question => {
      // Remove marking in case of recheck
      toUpdate[question.name] = { wrong: false, corrected: this.state[question.name].corrected };
      // Check the question
      if (!compareUrdu(question.a, this[question.name + "Ref"].value)) {
        toUpdate.mistakes++;
        toUpdate[question.name] = { wrong: true, corrected: this.state[question.name].corrected }; 
      }
    });
    this.setState(toUpdate);
    this.props.reportProgress({ mistakes: toUpdate.mistakes, outof: this.props.questions.length });
  }

  clearAnswers() {
    const toSet = { 
      mistakes: 0, 
      checked: false,
    };
    this.props.questions.forEach(question => {
      if (!question.given) this[question.name + "Ref"].value = "";
      toSet[question.name] = { wrong: false, corrected: false };
    });
    this.setState(toSet);
  }

  showOrHideAnswer(name) {
    const o = this.props.questions.find(e => e.name === name)
    if (this.state[name].corrected) {
      // Hide answer if button is clicked again
      this[name + "Ref"].value = "";
    } else {
      // If clicked for the first time, show the answer
      this[name + "Ref"].value = o.a[0];
    }
    this.setState(prevState => ({
      [name]: { corrected: this.state[name].corrected ? false : true, wrong: prevState[name].wrong }
    }))
  }

  render() {
    let paddingBottom = this.props.questions[0].englishA ? "1rem" : "0";
    if (this.props.questions[0].hintText) {
      paddingBottom = "3rem";
    }
    return (
        <div style={{ minWidth: "80%" }}>
          <div style={{ margin: "0 auto", maxWidth: "700px" }}>
          <h2>{this.props.title}</h2>
            {this.props.subText && <p>{this.props.subText}</p>}
            {this.props.image &&
              <img src={this.props.image.src} alt={this.props.image.alt || ""} style={{marginTop: "1rem"}} />
            }
            {this.props.questions.map(question => (
              <div key={question.name}>
                <div className={`question-container${this.props.seperateAnswerLine ? " question-container-seperate-answer-line" : ""}`} style={{ direction: this.props.dir || question.dir, paddingBottom }}>
                  <div className={`question-field${this.props.dir === "rtl" ? " question-field-rtl" : ""}`}>
                    <span style={{ margin: "0 0.5rem" }}>{question.name}.</span> 
                    {this.props.imageQuestions ?
                      <span>
                        <span style={{ display: "inline-flex", flexDirection: "column" }}>
                          <span style={{ display: "inline-flex", flexGrow: 0 }}>
                            {question.img.map((img) => (
                              <img key={img.alt} src={img.src} alt={img.alt} style={{ maxWidth: "110px", maxHeight: "200px" }} />
                            ))}
                          </span>
                          <span style={{ textAlign: "center" }}>{question.hintText}</span>
                        </span>
                      </span>
                    : 
                      question.q
                    }
                  </div>
                  <FormControl className="answer-field">
                    <TextField
                      id={question.name}
                      inputRef={input => this[question.name + "Ref"] = input}
                      InputProps={{
                        className: this.state[question.name].corrected ? this.classes[question.englishA ? "correctedEnglishInput" : "correctedInput"] : this.classes[question.englishA ? "englishInput" : "input"],
                        endAdornment: this.state[question.name].wrong && 
                          <InputAdornment position="start">
                            <Button style={{ color: "grey" }} onClick={() => this.showOrHideAnswer(question.name)}>
                              {this.state[question.name].corrected ? "Hide" : "Answer"}
                            </Button>
                          </InputAdornment>,
                      }} 
                      autoComplete="off" 
                      name={question.name} 
                      defaultValue={question.given ? question.a[0] : ""}
                      error={this.state[question.name].wrong}
                    />
                  </FormControl>
                </div>
              </div>
            ))}
            <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between", marginTop: "2rem" }}>
              <Button variant="contained" color="primary" onClick={this.checkAnswers} style={{ marginBottom: "1em" }}>
                {(this.state.checked && (this.state.mistakes !== 0)) ? "Recheck" : "Check"} Answers
              </Button>
              <div>
                {this.state.mistakes ? <Chip label={`${this.state.mistakes} mistakes`} color="secondary" style={{ marginBottom: "1em" }} /> : ''}  
                {(!this.state.mistakes && this.state.checked) && <Chip label={`All Correct!`} color="primary" />}
              </div>
              <Button varient="contained" color="primary" onClick={this.clearAnswers} style={{ marginBottom: "1em" }}>
                Clear Answers
              </Button>
            </div>
          </div>
        </div>
    );
  }
}

export default withStyles(styles)(LongBlanksExercise);
