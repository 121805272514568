export default [
    {
        name: "a",
        q: "میں",
        a: ["میں پچھلے سال یہاں کام کرتا تھا۔"],
        given: true,
    },
    {
        name: "b",
        q: "ہم",
        a: ["ہم پچھلے سال یہاں کام کرتے تھے۔"],
    },
    {
        name: "c",
        q: "وہ لڑکی",
        a: ["وہ لڑکی پچھلے سال یہاں کام کرتی تھی۔"],
    },
    {
        name: "d",
        q: "وہ لوگ",
        a: ["وہ لوگ پچھلے سال یہاں کام کرتے تھے۔"],
    },
    {
        name: "e",
        q: "تم (f)",
        a: ["تم پچھلے سال یہاں کام کرتی تھی۔"],
    },
    {
        name: "f",
        q: "وہ عورتیں",
        a: ["وہ عورتیں پچھلے سال یہاں کام کرتی تھیں۔"],
    },
    {
        name: "g",
        q: "میرے والد",
        a: ["میرے والد پچھلے سال یہاں کام کرتے تھے۔"],
    },
    {
        name: "h",
        q: "میرا بھائی",
        a: ["میرا بھائی پچھلے سال یہاں کام کرتا تھا۔"],
    },
    {
        name: "i",
        q: "آپ لوگ",
        a: ["آپ لوگ پچھلے سال یہاں کام کرتے تھے۔"],
    },
]
