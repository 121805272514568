import React from "react";
import questions from "./10-2-questions.js";
import LongBlanksExercise from "../../../components/LongBlanksExercise.js";

export default function exTenTwo(props) {
    return (
        <LongBlanksExercise 
            title="10.2. Write sentences using the verbs and tenses given below."
            dir="rtl"
            questions={questions}
            reportProgress={props.reportProgress}
        />
    );
}
