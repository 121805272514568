import React from "react";
import questions from "./3-3-questions.js";
import LongBlanksExercise from "../../../components/LongBlanksExercise.js";

export default function(props) {
    return (
        <LongBlanksExercise 
            title="3.3. What is the question? Put the words in the correct order to form questions."
            questions={questions} 
            answerEnding="question"
            dir="rtl"
            reportProgress={props.reportProgress}
        />
    );
}