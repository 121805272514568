export default [
    {
        name: "a",
        q: "ٓآپ / جانا",
        a: ["آپ کب جائیں گے؟"],
        given: true,
    },
    {
        name: "b",
        q: "ہم (m) کھانا",
        a: ["ہم  کب کھائیں گا؟"],
    },
    {
        name: "c",
        q: "وہ (f/s) پینا",
        a: ["وہ کب پیئے گی؟"],
    },
    {
        name: "d",
        q: "وہ (m/s) خریدنا",
        a: ["وہ کب خریدے گا؟"],
    },
    {
        name: "e",
        q: "وہ (f/pl) بیچنا",
        a: ["وہ کب بیچیں گی؟"],
    },
    {
        name: "f",
        q: "وہ (f/pl) پڑھنا",
        a: ["وہ کب پڑھیں گی؟"],
    },
    {
        name: "g",
        q: "میں (m) لکھنا",
        a: ["میں کب لکھوں گا؟"],
    },
    {
        name: "h",
        q: "میں (f) دیکھنا",
        a: ["میں کب دیکھوں گی؟"],
    },
    {
        name: "i",
        q: "تم (m) لینا",
        a: ["تم کب لو گے؟"],
    },
    {
        name: "j",
        q: "تم (f) دینا",
        a: ["تم کب دو گی؟"],
    },
];
