export default [
    {
        name: "a",
        q: "My friend (female) likes Bollywood movies.",
        a: ["میری دوست کو بالی وڈ فلمیں پسند ہے۔"],
    },
    {
        name: "b",
        q: "Do you know that Pakistani student’s name?",
        a: ["کیا آپ کو اس پاکستانی طالب علم کا نام معلوم ہے؟"],
    },
    {
        name: "c",
        q: "He likes to read Urdu poetry.",
        a: ["اس کو اردو شاعری پڑھنا پسند ہے۔"],
    },
    {
        name: "d",
        q: "The foreigner doesn’t know the name of this village.",
        a: ["غیر ملکی کو اس گاؤں کا نام معلوم نہیں۔"],
    },
    {
        name: "e",
        q: "She knows how to speak Urdu, but she doesn’t know how to read it.",
        a: [
            "اس کو اردو بولنا آتا ہے لیکن پڑھنا نہیں آتا ہے۔",
            "اس کو اردو بولنی آتی ہے لیکن پڑھنی نہیں آتی ہے۔",
        ],
    },
    {
        name: "f",
        q: "What can you buy (lit. what can you get) in this shop?",
        a: ["اس دکان سے کیا کیا ملتا ہے؟"],
    },
    {
        name: "g",
        q: "I like cold weather, but my wife likes hot weather.",
        a: ["مجھے ٹھنڈا موسم پسند ہے لیکن میری بیوی کو گرم موسم پسند ہے۔"],
    },
];
