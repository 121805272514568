export default [
    {
        name: "a",
        q: "میں / پاکستانی کھانا",
        a: ["مجھے پاکستانی کھانا پسند ہے۔"],
        given: true,
    },
    {
        name: "b",
        q: "وہ / امریکی لوگ",
        a: ["اس کو امریکی لوگ پسند ہیں۔"],
    },
    {
        name: "c",
        q: "آپ / اردو",
        a: [
            "آپ کو اُردو پسند ہے۔",
            "آپ کو اردو پسند ہے۔",
        ],
    },
    {
        name: "d",
        q: "یہ لوگ / ہندوستانی فلمیں",
        a: ["ان لوگوں کو ہندوستانی فلمیں پسند ہیں۔"],
    },
    {
        name: "e",
        q: "ہم / ٹھنڈا موسم",
        a: ["ہمیں ٹھنڈا موسم پسند ہے۔"],
    },
    {
        name: "f",
        q: "تم /  پاکستانی لوگ",
        a: ["تمہیں پاکستانی لوگ پسند ہیں۔"],
    },
    {
        name: "g",
        q: "لڑکی /  گانا گانا",
        a: ["لڑکی کو گانا گانا پسند ہے۔"],
    },
    {
        name: "h",
        q: "طالب علم / کتاب پڑھنا",
        a: ["طالب علم کو کتاب پڑھنا پسند ہے۔"],
    },
];
