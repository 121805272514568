import React from "react";
import questions from "./12-1-questions.js";
import LongBlanksExercise from "../../../components/LongBlanksExercise.js";

export default function exTwelveOne(props) {
    return (
        <LongBlanksExercise 
            title="What do you like? What do they like?"
            subText="Make new sentences using the hints given below and the verb construction pasand hai پسند ہے."
            questions={questions}
            dir="rtl"
            reportProgress={props.reportProgress}
        />
    );
}
