export default [
    {
        name: "a",
        qStart: "تمہارے بستے میں",
        qEnd: "ہے؟",
        a: "کیا",
    },
    {
        name: "b",
        qStart: "",
        qEnd: "رو رہا ہے؟",
        a: "کون",
    },
    {
        name: "c",
        qStart: "یہ قمیض",
        qEnd: "ہے؟",
        a: "کیسی",
    },
    {
        name: "d",
        qStart: "آپ کا اسکول",
        qEnd: "ہے؟",
        a: "کہاں",
    },
    {
        name: "e",
        qStart: "آپ",
        qEnd: "ہیں؟",
        a: "کیسے",
    },
];
