import React from "react";
import questions from "./7-3-questions.js";
import LongBlanksExercise from "../../../components/LongBlanksExercise.js";

export default function exSevenThree(props) {
    return (
        <LongBlanksExercise 
            title="7.3. Is this yours? Mine? Theirs? Form sentences using the correct possessive pronoun."
            questions={questions}
            answerEnding="statement"
            reportProgress={props.reportProgress}
        />
    );
}
