export default [
    {
        name: "a",
        q: "I will go home in the evening and (will) study.",
        a: ["میں شام کو گھر جا ؤں گی اور پڑھائی کروں گی۔"],
    },
    {
        name: "b",
        q: "She will come tomorrow in the afternoon.",
        a: ["وہ کل دوپہر میں آئے گی۔"],
    },
    {
        name: "c",
        q: "Next year my brother will live in New York.",
        a: ["اگلے سال میرا بھائی نیو یارک میں رہے گا۔"],
    },
    {
        name: "d",
        q: "What will you do about this?",
        a: ["تم اس  کےبارے میں کیا کروگے؟"],
    },
    {
        name: "e",
        q: "They will watch a film tonight.",
        a: ["وہ رات کو فلم  دیکھیں گے۔"],
    },
    {
        name: "f",
        q: "We will meet you tomorrow.",
        a: ["ہم تم سے کل ملیں گے۔"],
    },
    {
        name: "g",
        q: "In Pakistan, we will only speak Urdu.",
        a: ["پاکستان میں ہم صرف اردو بولیں گے۔"],
    },
    {
        name: "h",
        q: "Will you people drink tea? No, we will drink coffee.",
        a: ["کیا آپ  لوگ چائے پیئں گے ؟ نہیں ہم کافی پیئں گے۔"],
    },
    {
        name: "i",
        q: "How many questions will you ask?",
        a: ["آپ  کتنے سوال پوچھیں گے ؟"],
    },
    {
        name: "j",
        q: "How much money will you take?",
        a: ["آپ کتنے پیسے لیں گی ؟"],
    },
];
