export default [
    {
        name: "a",
        q: "کیا وہ استاد ہیں؟",
        a: ["Is this a teacher?"],
        given: true,
        englishA: true,
        dir: "rtl",
    },
    {
        name: "b",
        q: "آپ کیسے ہیں؟",
        a: ["How are you?"],
        englishA: true,
        dir: "rtl",
    },
    {
        name: "c",
        q: "یہ کون ہے؟",
        a: ["Who is this?"],
        englishA: true,
        dir: "rtl",
    },
    {
        name: "d",
        q: "آپ چائے کب پیتے ہیں؟",
        a: ["When do you drink tea?"],
        englishA: true,
        dir: "rtl",
    },
    {
        name: "e",
        q: "کیا آپ طالب علم ہیں؟",
        a: ["Are you a student?"],
        englishA: true,
        dir: "rtl",
    },
    {
        name: "f",
        q: "سارہ کیسی ہے؟",
        a: ["How is Sara?", "How is Sarah?"],
        englishA: true,
        dir: "rtl",
    },
    {
        name: "g",
        q: "کیا آپ فرانسیسی ہیں؟",
        a: ["Are you French?"],
        englishA: true,
        dir: "rtl",
    },
    {
        name: "h",
        q: "وہ کیسی ہیں؟",
        a: ["How is she doing?"],
        englishA: true,
        dir: "rtl",
    },
    {
        name: "i",
        q: "آپ کب کھاتے ہیں؟",
        a: ["When do you eat?"],
        englishA: true,
        dir: "rtl",
    },
    {
        name: "j",
        q: "وہاں کون ہے؟",
        a: ["Who is there?"],
        englishA: true,
        dir: "rtl",
    },
    {
        name: "k",
        q: "Is America a big country?",
        a: ["کیا امریکا ایک بڑا ملک ہے؟", "کیا امریکہ ایک بڑا ملک ہے؟"],
        dir: "ltr",
    },
    {
        name: "l",
        q: "What is this?",
        a: ["یہ کیا ہے؟"],
        dir: "ltr",
    },
    {
        name: "m",
        q: "Is this university big?",
        a: ["کیا یہ یونیورسٹی بڑی ہے؟"],
        dir: "ltr",
    },
    {
        name: "n",
        q: "How is the food?",
        a: ["کھانا کیسا ہے؟"],
        dir: "ltr",
    },
    {
        name: "o",
        q: "Is she a good student?",
        a: ["کیا وہ اچھی طالب علم ہے؟"],
        dir: "ltr",
    },
    {
        name: "p",
        q: "Where is Lahore?",
        a: ["لاہور کہاں ہے؟"],
        dir: "ltr",
    },
    {
        name: "q",
        q: "Who are those people?",
        a: ["وہ لوگ کون ہیں؟"],
        dir: "ltr",
    },
    {
        name: "r",
        q: "How is the class?",
        a: ["کلاس کیسی ہے؟"],
        dir: "ltr",
    },
    {
        name: "s",
        q: "Where is the library?",
        a: ["لائبریری کہاں ہے؟"],
        dir: "ltr",
    },
]