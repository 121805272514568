export default [
    {
        name: "a",
        q: "Please give this.",
        a: ["آپ یہ دیجیے۔"],
        given: true,
    },
    {
        name: "b",
        q: "Please take this.",
        a: ["آپ یہ لیجیے۔"],
    },
    {
        name: "c",
        q: "Please do this.",
        a: ["آپ یہ کیجیے۔"],
    },
    {
        name: "d",
        q: "Please drink this.",
        a: ["آپ یہ پیجیے۔"],
    },
    {
        name: "e",
        q: "Please do this work.",
        a: ["آپ یہ کام کیجیے۔"],
    },
    {
        name: "f",
        q: "Please talk.",
        a: ["آپ بات کیجیے۔"],
    },
];
