export default [
    {
        name: "a",
        q: "روٹی",
        a: ["روٹی کہاں ملتی ہے؟"],
        given: true,
    },
    {
        name: "b",
        q: "اردو کی کتابیں",
        a: ["اردو کی کتابیں کہاں ملتی ہیں؟"],
    },
    {
        name: "c",
        q: "اچھے کپڑے",
        a: ["اچھے کپڑے کہاں ملتے ہیں؟"],
    },
    {
        name: "d",
        q: "خوبصورت تصویریں",
        a: ["خوبصورت تصویریں کہاں ملتی ہیں؟"],
    },
    {
        name: "e",
        q: "سستے پھل",
        a: ["سستے پھل کہاں ملتے ہیں؟"],
    },
    {
        name: "f",
        q: "اچھا کھانا",
        a: ["اچھا کھانا کہاں ملتا ہے؟"],
    },
];
