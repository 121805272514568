export default [
    {
        name: "a",
        q: "میں/ کلاس",
        a: ["ہم کلاس میں ہیں۔"],
        given: true,
    },
    {
        name: "b",
        q: "کے سامنے /بازار",
        a: ["ہم بازار کے سامنے ہیں۔"],
    },
    {
        name: "c",
        q: "کے پیچھے/ دکان",
        a: ["ہم دکان کے پیچھے ہیں۔"],
    },
    {
        name: "d",
        q: "میں / امریکا",
        a: ["ہم امریکا میں ہیں۔"],
    },
    {
        name: "e",
        q: "سے باہر/ شہر",
        a: ["ہم شہر سے باہر ہیں۔"],
    },
    {
        name: "f",
        q: "کے پاس / اسکول",
        a: ["ہم اسکول کے پاس ہیں۔"],
    },
];