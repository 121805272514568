import React from "react";
import questions from "./5-1-questions.js";
import LongBlanksExercise from "../../../components/LongBlanksExercise.js";

function exFiveOne(props) {
    return (
        <LongBlanksExercise 
            title="5.1. Make polite requests."
            questions={questions}
            dir="ltr" 
            answerEnding="statement"
            reportProgress={props.reportProgress}
        />
    );
}

export default exFiveOne;