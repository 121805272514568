import React from 'react';
import {
  FormControl,
  TextField,
  Button,
  Chip,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import styles from "../../../styles/exercise-styles.js";
import cleanUrdu from "../../../lib/clean-urdu.js";
import questions from './2-1-questions.js';
import InputAdornment from '@material-ui/core/InputAdornment';

function compareUrdu(answer, input) {
  // Doesn't matter what digits they use or don't the important thing is writing the plural
  const cleaned = cleanUrdu(input).replace(/[0-9]/g, "").replace(/ /g, "");
  if (answer.includes(cleaned)) return true
  return false
}

class ExerciseTwoOne extends React.Component {
  constructor(props) {
    super(props); 
    const questionsState = {};
    questions.forEach(question => {
      this[question.name + "Ref"] = React.createRef();
      questionsState[question.name] = { 
        wrong: false,
        corrected: false,
      };
    });
    this.state = {
      mistakes: 0,
      checked: false,
      ...questionsState,
    };
    this.handleChange = this.handleChange.bind(this);
    this.checkAnswers = this.checkAnswers.bind(this);
    this.clearAnswers = this.clearAnswers.bind(this);
    this.showOrHideAnswer = this.showOrHideAnswer.bind(this);

    this.classes = this.props.classes;
  }

  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({ [name]: value });
  }

  checkAnswers() {
    const toUpdate = { mistakes: 0, checked: true };
    // Go through and check each question
    questions.forEach(question => {
      // Remove marking in case of recheck
      toUpdate[question.name] = { wrong: false, corrected: this.state[question.name].corrected };
      // Check the question
      if (!compareUrdu(question.a, this[question.name + "Ref"].value)) {
        toUpdate.mistakes++;
        toUpdate[question.name] = { wrong: true, corrected: this.state[question.name].corrected }; 
      }
    });
    this.setState(toUpdate);
    this.props.reportProgress({ mistakes: toUpdate.mistakes, outof: questions.length });
  }

  clearAnswers() {
    const toSet = { 
      mistakes: 0, 
      checked: false,
    };
    questions.forEach(question => {
      if (!question.given) this[question.name + "Ref"].value = question.num + " ";
      toSet[question.name] = { wrong: false, corrected: false };
    });
    this.setState(toSet);
  }

  showOrHideAnswer(name) {
    const o = questions.find(e => e.name === name)
    if (this.state[name].corrected) {
      // Hide answer if button is clicked again
      this[name + "Ref"].value = "";
    } else {
      // If clicked for the first time, show the answer
      this[name + "Ref"].value = o.num + " " + o.a[Math.round(Math.random())];
    }
    this.setState(prevState => ({
      [name]: { corrected: this.state[name].corrected ? false : true, wrong: prevState[name].wrong }
    }))
  }

  render() {
    return (
      <div style={{ margin: "0 auto", maxWidth: "700px" }}>
        <h2>2.1. Write the plural</h2>
          {questions.map(question => (
            <div key={question.name}>
              <div className="question-container" style={{ direction: "rtl" }}>
                <div className="question-field">
                  <span>{question.name}. </span><span style={{ direction: "rtl" }}>ایک {question.q}</span>
                </div>
                <FormControl className="answer-field">
                  <TextField
                    id={question.name}
                    inputRef={input => this[question.name + "Ref"] = input}
                    InputProps={{
                      className: this.state[question.name].corrected ? this.classes.correctedInput : this.classes.input,
                      endAdornment: this.state[question.name].wrong && 
                        <InputAdornment position="start">
                          <Button style={{ color: "grey" }} onClick={() => this.showOrHideAnswer(question.name)}>
                            {this.state[question.name].corrected ? "Hide" : "Answer"}
                          </Button>
                        </InputAdornment>,
                    }} 
                    autoComplete="off" 
                    name={question.name} 
                    defaultValue={`${question.num} ${question.given ? question.a : ""}`}
                    error={this.state[question.name].wrong}
                  />
                </FormControl>
              </div>
            </div>
          ))}
          <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between", marginTop: "2rem" }}>
            <Button variant="contained" color="primary" onClick={this.checkAnswers} style={{ marginBottom: "1em" }}>
              {(this.state.checked && (this.state.mistakes !== 0)) ? "Recheck" : "Check"} Answers
            </Button>
            <div>
              {this.state.mistakes ? <Chip label={`${this.state.mistakes} mistakes`} color="secondary" style={{ marginBottom: "1em" }} /> : ''}  
              {(!this.state.mistakes && this.state.checked) && <Chip label={`All Correct!`} color="primary" />}
            </div>
            <Button varient="contained" color="primary" onClick={this.clearAnswers} style={{ marginBottom: "1em" }}>
              Clear Answers
            </Button>
          </div>
        </div>
    );
  }
}

ExerciseTwoOne.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ExerciseTwoOne);
