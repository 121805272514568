import React from "react";
import questions from "./27-3-questions.js";
import LongBlanksExercise from "../../../components/LongBlanksExercise.js";

export default function exTwentySevenThree(props) {
    return (
        <LongBlanksExercise 
            title="27.3. Are the following sentences correct?"
            subText="If the sentence is correct, leave the answer blank. Otherwise write the corrected sentence in the blank."
            questions={questions}
            dir="rtl"
            reportProgress={props.reportProgress}
        />
    );
}
