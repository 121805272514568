import React from "react";
import questions from "./17-4-questions.js";
import LongBlanksExercise from "../../../components/LongBlanksExercise.js";
import livingRoom from "../../../images/ex17-4/living-room.png";

export default function exElevenFour(props) {
    return (
        <LongBlanksExercise 
            title="Look and describe"
            subText="Look at the picture and describe what various family members will do on the weekend. Use the hints given below."
            image={{ src: livingRoom, alt: "A Pakistani family in a living room" }}
            questions={questions}
            reportProgress={props.reportProgress}
        />
    );
}
