import React from "react";
import questions from "./7-1-questions.js";
import LongBlanksExercise from "../../../components/LongBlanksExercise.js";

export default function exSevenOne(props) {
    return (
        <LongBlanksExercise 
            title="7.1. Change the sentences given below from singular to plural."
            subText="Make sure to use the correct possessive form (ki/ka/ke کی/ کا/ کے)"
            questions={questions}
            answerEnding="statement"
            dir="rtl"
            reportProgress={props.reportProgress}
        />
    );
}
