import React from "react";
import questions from "./15-3-questions.js";
import LongBlanksExercise from "../../../components/LongBlanksExercise.js";

export default function exFifteenThree(props) {
    return (
        <LongBlanksExercise 
            title="Now and Then."
            subText="Look at each pair of pictures and write sentences stating what they used to do and what they do now."
            questions={questions}
            dir="ltr"
            imageQuestions
            reportProgress={props.reportProgress}
        />
    );
}
