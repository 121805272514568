import React from "react";
import questions from "./4-2-questions.js";
import LongBlanksExercise from "../../../components/LongBlanksExercise.js";

function exFourTwo(props) {
    return (
        <LongBlanksExercise 
            title="4.2. Put the words in the correct order."
            questions={questions}
            dir="rtl" 
            answerEnding="statement"
            reportProgress={props.reportProgress}
        />
    );
}

export default exFourTwo;