import React from 'react';
import {
  Button,
  Chip,
} from "@material-ui/core";
import styles from "../styles/exercise-styles.js";
import { withStyles } from "@material-ui/core/styles";
import { Stage, Layer, Rect, Arrow } from 'react-konva';

const canvasWidth = 800;
let canvasHeight = 0;
const spaceBetweenImg = 30;
const rectDim = { w:80, h:60 };
let rightSide;
let imageRows; // Needed to store as variable outside of props - so that the y value can be calculated and added


class MatchingArrowsExercise extends React.Component {
  constructor(props) {
    super(props);
    // initialization calculations related to images and size etc.
    imageRows = this.props.imageRows;
    // Calculate special Ys
    let cumY = 10;
    imageRows.forEach((row, i) => {
      imageRows[i].y = cumY;
      imageRows[i].m = cumY + (imageRows[i].h / 2) - rectDim.h;
      cumY += imageRows[i].h + spaceBetweenImg;
    });
    canvasHeight = cumY;

    // Calculate Right Side
    let widestWordLength = 0;
    this.props.wordRows.forEach((row, i) => {
      if (row.w > widestWordLength) widestWordLength = row.w;
    })
    rightSide = 795 - widestWordLength;

    // Setup stage and state
    this.stageParent = React.createRef()
    this.state = {
      stageWidth: canvasWidth,
      stageHeight: canvasHeight,
      stageScaleX: 1,
      stageScaleY: 1,
      drawingArrowFromRight: -1,
      drawingArrowFromLeft: -1,
      arrows: this.props.givenArrow ? [this.props.givenArrow] : [],
      mistakes: 0,
      checked: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.checkAnswers = this.checkAnswers.bind(this);
    this.clearAnswers = this.clearAnswers.bind(this);
    this.fitStageIntoParentContainer = this.fitStageIntoParentContainer.bind(this);
    this.handleClickOrTap = this.handleClickOrTap.bind(this);
    this.handleWordClick = this.handleWordClick.bind(this);
    this.handleImageClick = this.handleImageClick.bind(this);
    this.classes = this.props.classes;
  }

  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({ [name]: value });
  }

  checkAnswers() {
    let checkedArrows = []
    let mistakes = 0
    if (this.state.arrows.length < imageRows.length) {
      alert("Please connect all the prounouns to their pictures");
      return;
    }
    this.state.arrows.forEach(a => {
      const possibleAnswers = imageRows[a.end].word;
      if (possibleAnswers.includes(a.start)) {
        const toAdd = {...a};
        checkedArrows.push(toAdd);
      } else {
        const toAdd = {...a, wrong: true};
        checkedArrows.push(toAdd)
        mistakes++
      }
    });
    this.setState({
      arrows: checkedArrows,
      checked: true,
      mistakes: mistakes,
      drawingArrowFromLeft: -1,
      drawingArrowFromRight: -1,
    });
    this.props.reportProgress({ mistakes: mistakes, outof: checkedArrows.length });
  }

  clearAnswers() {
    this.setState({ 
      arrows: [],
      checked: false,
      drawingArrowFromLeft: -1,
      drawingArrowFromRight: -1,
      mistakes: 0,
    });
  }

  handleWordClick(id) {
    const pos = id - imageRows.length;
    if (this.state.drawingArrowFromRight !== -1) return;
    if (this.state.drawingArrowFromLeft === -1) {
      // Fresh Click on the Right side
      // if there's an arrow on the thing clicked, remove it
      if (this.state.arrows.find(a => (a.start === pos))) {
        this.setState(prevState => ({
          drawingArrowFromRight: -1,
          drawingArrowFromLeft: -1,
          arrows: prevState.arrows.filter(a => (a.start !== pos))
        }));
      } else {
        // Start new line from word
        this.setState({ 
          drawingArrowFromRight: pos,
          drawingArrowFromLeft: -1, 
        });
      }
    } else {
      // Has started to draw on the left, will connect arrow if possible
      // don't allow the connection if the word is already taken
      if (this.state.arrows.find(a => a.start === pos)) return; // maybe also reset arrow here?
      // word is free, link it up
      this.setState(prevState => ({
        drawingArrowFromRight: -1,
        drawingArrowFromLeft: -1,
        arrows: [...prevState.arrows, {start: pos, end: prevState.drawingArrowFromLeft, direction: "ltr"}],
      }));
    }  
  }

  handleImageClick(id) {
    const pos = id;
    if (this.state.drawingArrowFromRight === -1) {
      // Fresh Click on the Left side
      // if there's an arrow on the thing clicked, remove it
      if (this.state.arrows.find(a => (a.end === pos))) {
        this.setState(prevState => ({
          drawingArrowFromRight: -1,
          drawingArrowFromLeft: -1,
          arrows: prevState.arrows.filter(a => (a.end !== pos))
        }));
      } else {
        // Start new line from word
        this.setState({ 
          drawingArrowFromLeft: pos,
          drawingArrowFromRight: -1, 
        });
      }
    } else {
      // Has started to draw on the right, will connect arrow if possible
      // don't allow the connection if the word is already taken
      if (this.state.arrows.find(a => a.end === pos)) return; // maybe also reset arrow here?
      // word is free, link it up
      // console.log({end: pos, start: this.state.drawingArrowFromRight});
      this.setState(prevState => ({
        drawingArrowFromLeft: -1,
        drawingArrowFromRight: -1,
        arrows: [...prevState.arrows, {end: pos, start: prevState.drawingArrowFromRight, direction: "rtl"}],
      }));
    }   
  }
 
  handleClickOrTap(event) {
    const id = event.target.attrs.id;
    console.log(id)
    if (id >= imageRows.length) {
      // User clicked on an word (right side)
      this.handleWordClick(id);
    } else if (id < imageRows.length) {
      this.handleImageClick(id);
    }
  }

  fitStageIntoParentContainer() {
    // TODO: !!!!! Breaks when making window bigger
    const containerWidth = this.stageParent.current && this.stageParent.current.offsetWidth;
    // to do this we need to scale the stage
    const scale = containerWidth / canvasWidth;

    this.setState({
      stageWidth: canvasWidth * scale,
      stageHeight: canvasHeight * scale,
      stageScaleX: scale,
      stageScaleY: scale,
    })
  }
  
  componentDidMount() {
    this.fitStageIntoParentContainer();
    window.addEventListener("resize", this.fitStageIntoParentContainer);
  }

  render() {
    return (
        <div style={{ maxWidth: "700px", margin: "0 auto"}}>
          <h2>{this.props.title}</h2>
          {this.props.subText && <p style={{ lineHeight: 1.2 }}>{this.props.subText}</p>}
          <div style={{ maxWidth: "500px", margin: "2rem auto 2rem auto" }}>
            <div ref={this.stageParent}>
              <Stage 
                width={this.state.stageWidth} height={this.state.stageHeight} scaleX={this.state.stageScaleX} scaleY={this.state.stageScaleY}
                onClick={this.handleClickOrTap}
                onTap={this.handleClickOrTap} 
              >
                <Layer>
                  {imageRows.map((img, i) => (
                    <MyImage id={i} key={i} x={10} y={img.y} width={img.w} height={img.h} img={img.src}
                      selected={this.state.drawingArrowFromLeft === i} 
                    />
                  ))}

                  {this.props.wordRows.map((pr, i) => {
                    return (
                      <MyImage 
                        id={i+imageRows.length}
                        key={i} x={rightSide} y={imageRows[i].m} width={pr.w} height={pr.h} img={pr.src}
                        selected={this.state.drawingArrowFromRight === i}
                      />
                    )
                  })}

                  {this.state.arrows.map((a, i) => {
                    let x, y, endY, points
                    if (a.direction === "rtl") {
                      // Start the arrow just to the left of the word box, in the middle of the word box
                      x = rightSide - 5;
                      y = imageRows[a.start].m + (this.props.wordRows[a.start].h / 2);
                      // Aim just to the right of the image box, in the middle of the image box
                      endY = imageRows[a.end].m + 35;
                      points = [0, 0, -(x - imageRows[a.end].w - 10), endY - y];
                    } else {
                      // Start the arrow just to the right of the image box, in the middle of the image box
                      x = 10 + imageRows[a.end].w + 5;
                      y = imageRows[a.end].y + (imageRows[a.end].h / 2) - 20;
                      // Aim just to the left of the word box, in the middle of the word box 
                      endY = imageRows[a.start].m + 35;
                      points = [0, 0, rightSide - x - 10, endY - y];
                    }
                    const color = a.wrong ? "red" : "black";
                    return (
                      <Arrow key={i} x={x} y={y} points={points} fill={color} stroke={color} strokeWidth={3} />
                    )
                  })}
                </Layer>
              </Stage>
            </div>
          </div>
          <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between", maxWidth: "650px", margin: "0 auto" }}>
            <Button variant="contained" color="primary" onClick={this.checkAnswers} style={{ marginBottom: "1em" }}>
              {(this.state.checked && (this.state.mistakes !== 0)) ? "Recheck" : "Check"} Answers
            </Button>
            <div>
              {this.state.mistakes ? <Chip label={`${this.state.mistakes} mistakes`} color="secondary" style={{ marginBottom: "1em" }} /> : ''}  
              {(!this.state.mistakes && this.state.checked) && <Chip label={`All Correct!`} color="primary" />}
            </div>
            <Button varient="contained" color="primary" onClick={this.clearAnswers} style={{ marginBottom: "1em" }}>
              Clear Answers
            </Button>
          </div>
        </div>
    );
  }
}

class MyImage extends React.Component {
  constructor(...args) {
    super(...args);
    const image = new window.Image();
    image.onload = () => {
      this.setState({
        fillPatternImage: image
      });
    }
    image.src = this.props.img;
    this.state = {
      color: 'green',
      fillPatternImage: null
    };
  }
  render() {
      const { selected } = this.props;
      return (
          <Rect
            id={this.props.id}
            x={this.props.x} y={this.props.y} 
            width={this.props.width} height={this.props.height}
            shadowOffsetX={selected ? 4 : undefined}
            shadowOffsetY={selected ? 4 : undefined}
            stroke={selected ? "grey" : undefined}
            shadowBlur={selected ? 30 : undefined}
            shadowOpacity={selected ? 0.5 : undefined}
            fillPatternImage={this.state.fillPatternImage}
            fillPatternRepeat={"no-repeat"}
            fillPatternScaleX={1}
            fillPatternScaleY={1}
          />
      );
  }
}

export default withStyles(styles)(MatchingArrowsExercise);
