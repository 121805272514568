export default [
    {
        name: "a",
        q: "غیر ملکی / اردو",
        a: ["غیر ملکی کو اردو آتی ہے۔"],
        given: true,
    },
    {
        name: "b",
        q: "میں / کھانا پکانا",
        a: ["مجھے کھانا پکانا آتا ہے۔"],
    },
    {
        name: "c",
        q: "آپ / گاڑی چلانا",
        a: [
            "آپ کو گاڑی چلانا آتا ہے۔",
            "آپ کو گاڑی چلانی آتی ہے۔",
        ],
    },
    {
        name: "d",
        q: "ہم / کمپیوٹر چلانا",
        a: ["ہمیں کمپیوٹر چلانا آتا ہے۔"],
    },
    {
        name: "e",
        q: "وہ / ساز بجانا",
        a: ["اس کو ساز بجانا آتا ہے۔"],
    },
    {
        name: "f",
        q: "اس طالب علم / پڑھنا",
        a: ["اس طالب علم کو پڑھنا آتا ہے۔"],
    },
];
