import React from "react";
import questions from "./10-3-questions.js";
import LongBlanksExercise from "../../../components/LongBlanksExercise.js";

export default function exTenThree(props) {
    return (
        <LongBlanksExercise 
            title="10.3. What did you do yesterday?"
            subText="Write sentences using the “kar” constructions and the hints given."
            questions={questions}
            reportProgress={props.reportProgress}
        />
    );
}
