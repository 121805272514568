export default [
    {
        name: "a",
        q: "That book is under the table.",
        a: ["وہ کتاب میز کے نیچے ہے۔"],
    },
    {
        name: "b",
        q: "Don’t sit near the window!",
        a: ["کھڑکی کے پاس مت بیٹھو!"],
        answerEnding: "!",
    },
    {
        name: "c",
        q: "Who is behind you?",
        a: ["آپ کے پیچھے کون ہے؟"],
        answerEnding: "؟",
    },
    {
        name: "d",
        q: "There is a big tree in front of the library.",
        a: ["لا ئبری کے سامنے ایک بڑا درخت ہے۔"],
    },
];