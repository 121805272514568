import fourClock from "../../../images/ex15-3/4clock.jpg";
import twelveClock from "../../../images/ex15-3/12clock.jpg";
import sevenThirtyClock from "../../../images/ex15-3/730clock.jpg";
import tenOFiveClock from "../../../images/ex15-3/1005clock.jpg";
import elevenTwentyClock from "../../../images/ex15-3/1120clock.jpg";
import eightFifteenClock from "../../../images/ex15-3/815clock.jpg";
// import cards from "../../../images/ex15-3/cards.png";
import chef from "../../../images/ex15-3/chef.png";
import cooking from "../../../images/ex15-3/cooking.png";
// import couchtalk from "../../../images/ex15-3/couchtalk.png";
import eating from "../../../images/ex15-3/eating.png";
import football from "../../../images/ex15-3/football.png";
import office from "../../../images/ex15-3/office.png";
import running from "../../../images/ex15-3/running.png";
import sleeping from "../../../images/ex15-3/sleeping.png";
import studying from "../../../images/ex15-3/studying.png";
import watchingTv from "../../../images/ex15-3/watching-tv.png";

export default [
    {
        name: "a",
        img: [
            { src: eightFifteenClock, alt: "clock showing 8:15" },
            { src: eating, alt: "man eating breakfast" },
        ],
        a: ["پہلے وہ ناشتہ سوا آٹھ بجے کرتا تھا۔"],
        given: true,
    },
    {
        name: "b",
        img: [{ src: sevenThirtyClock, alt: "clock showing 7:30" }],
        a: ["ابھی وہ ناشتہ ساڑھے آٹھ بجے کرتا ہے۔"],
        given: true,
    },
    {
        name: "c",
        img: [{ src: football, alt: "man playing soccer" }],
        a: ["پہلے وہ فٹ بال کھیلتا تھا۔"],
    },
    {
        name: "d",
        img: [{ src: running, alt: "man running" }],
        a: ["ابھی وہ بھاگتا ہے۔"],
    },
    {
        name: "e",
        img: [
            { src: twelveClock, alt: "clock showing 12:00" },
            { src: office, alt: "woman working in an office" },
        ],
        a: ["پہلے وہ بارہ بجے تک دفتر میں کام کرتی تھی۔"],
    },
    {
        name: "f",
        img: [{ src: fourClock, alt: "clock showing 4:00" }],
        a: ["ابھی وہ چار بجے تک دفترمیں کام کرتی ہے۔"],
    },
    {
        name: "g",
        img: [{ src: cooking, alt: "woman cooking" }],
        a: ["پہلے وہ خود کھانا پکاتا تھا۔"],
        hint: "خود",
    },
    {
        name: "h",
        img: [{ src: chef, alt: "chef serving food" }],
        a: ["ابھی وہ ہوٹل میں کھانا کھاتا ہے۔"],
        hint: "ہوٹل میں"
    },
    {
        name: "i",
        img: [{ src: studying, alt: "man studying" }],
        a: ["پہلے شام کو وہ پڑھتا تھا۔"],
        hint: "شام کو وه"
    },
    {
        name: "j",
        img: [{ src: watchingTv, alt: "man watching tv" }],
        a: ["ابھی وہ شام کو ٹی وی دیکھتا ہے۔"],
    },
    {
        name: "k",
        img: [
            { src: elevenTwentyClock, alt: "clock showing 11:20" },
            { src: sleeping, alt: "man sleeping" },
        ],
        a: ["پہلے وہ رات کو 11:20 پر سوتا تھا۔"],
    },
    {
        name: "l",
        img: [{ src: tenOFiveClock, alt: "clock showing 10:05" }],
        a: ["ابھی وہ رات کو 10:05 پر سوتا ہے۔"],
    },
];
