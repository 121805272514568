import React from "react";
import questions from "./6-1-questions.js";
import LongBlanksExercise from "../../../components/LongBlanksExercise.js";

function exSixOne(props) {
    return (
        <LongBlanksExercise 
            title="6.1. Where are we?"
            subText="Complete the sentences below using the cues given. "
            questions={questions}
            dir="rtl" 
            answerEnding="statement"
            reportProgress={props.reportProgress}
        />
    );
}

export default exSixOne;
