export default [
    {
        name: "a",
        q: "وہ / کام ختم کرنا / گھر جانا (past)",
        a: ["وہ کام ختم کر کے گھر گیا۔"],
    },
    {
        name: "b",
        q: "وہ / دوست سے بات کرنا / پڑھائی کرنا (past)",
        a: ["اس نے دوست سے بات کر کے پڑھائی کی۔"],
    },
    {
        name: "c",
        q: "وہ / گاڑی کی مرمت کرنا/ گھر آنا (future) ",
        a: ["وہ گاڑی کی مرمت کر کے گھر آئے گا۔"],
    },
    {
        name: "d",
        q: "میں / دروازے بند کرنا/ باہر نکلنا (simple present)",
        a: [
            "میں دروازے بند کر کے باہر نکلتی ہوں۔",
            "میں دروازے بند کر کے باہر نکلتا ہوں۔",
        ],
    },
    {
        name: "e",
        q: "میں /آپ کا انتظار کرنا / چلے جانا (past)",
        a: ["ٓآپ  کا انتظار کر کے  میں چلا گیا۔"],
    },
    {
        name: "f",
        q: "ماں/ کمرا صاف کرنا / کھانا بنانا (future)",
        a: ["ماں کمرا صاف کر کے کھانا بنائے گی۔"],
    },
    {
        name: "g",
        q: "لڑکی / دوست کو فون کرنا / بات بتانا (past)",
        a: ["لڑکی نے دوست کو فون کر کے بات بتائی۔"],
    },
];
