import React from "react";
import questions from "./11-1-questions.js";
import LongBlanksExercise from "../../../components/LongBlanksExercise.js";

export default function exElevenOne(props) {
    return (
        <LongBlanksExercise 
            title="What are you doing right now?"
            subText="Write sentences using the present progressive tense."
            questions={questions}
            dir="rtl"
            reportProgress={props.reportProgress}
        />
    );
}
