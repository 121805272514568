import React from "react";
import questions from "./3-1-questions.js";
import LongBlanksExercise from "../../../components/LongBlanksExercise.js";

function exThreeOne(props) {
    return (
        <LongBlanksExercise 
            title="3.1. Write Yes/No questions."
            questions={questions} 
            answerEnding="question"
            reportProgress={props.reportProgress}
        />
    );
}

export default exThreeOne;