import React from "react";
import questions from "./17-1-questions.js";
import LongBlanksExercise from "../../../components/LongBlanksExercise.js";

function exSevenOne(props) {
    return (
        <LongBlanksExercise 
            title="7.1. Write questions as in the example given."
            questions={questions}
            dir="rtl"
            reportProgress={props.reportProgress}
        />
    );
}

export default exSevenOne;