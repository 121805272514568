export default [
    {
        name: "a",
        q: "خوبصورت - ہے - یہ - ساڑھی - ایک",
        a: ["یہ ایک خوبصورت ساڑھی ہے۔"],
    },
    {
        name: "b",
        q: "وه - ہے - پھل - کھٹا",
        a: ["وہ پھل کھٹا ہے۔"],
    },
    {
        name: "c",
        q: "ہوں - شریف - آدمی - میں",
        a: ["میں شریف آدمی ہوں۔"],
    },
    {
        name: "d",
        q: "نارنجی - ہے - کتاب - وہ",
        a: ["وہ نارنجی کتاب ہے۔"],
    },
    {
        name: "e",
        q: "وه - اچھے - لڑکیاں - لڑکے - بچے - ہیں",
        a: ["وہ لڑکے اور لڑکیاں اچھے بچے ہیں۔"],
    },
]