export default [
    {
        name: "a",
        q: "House/your",
        a: ["کیا یہ آپ کا گھر ہے؟"],
        given: true,
    },
    {
        name: "b",
        q: "Class/his",
        a: ["کیا یہ اس کی کلاس ہے؟"],
    },
    {
        name: "c",
        q: "Big chair/your",
        a: ["کیا یہ آپ کی بڑی کرسی ہے؟"],
    },
    {
        name: "d",
        q: "Brother/your",
        a: ["کیا وہ آپ کا بھائی ہے؟"],
    },
    {
        name: "e",
        q: "Correct answer/her",
        a: ["کیا اس کا جواب صحیح ہے؟"],
    },
    {
        name: "f",
        q: "Shop/whose",
        a: ["وہ کس کی دکان ہے؟"],
    },
    {
        name: "g",
        q: "Expensive Table/Ahmed",
        a: ["یہ احمد کی مہنگی میز ہے۔"],
        given: true,
    },
    {
        name: "h",
        q: "Big office/Ali",
        a: ["وہ علی کا بڑا دفترہے۔"],
    },
    {
        name: "i",
        q: "Good clothes/ours",
        a: ["یہ ہمارے اچھے کپڑے ہیں۔"],
    },
    {
        name: "j",
        q: "Expensive shirt/Hamza",
        a: ["یہ حمزہ کی مہنگی قمیض ہے۔"],
    },
    {
        name: "k",
        q: "Room/his",
        a: ["وہ اس کا کمرہ ہے۔"],
    },
    {
        name: "l",
        q: "Big house/theirs",
        a: ["یہ ان کا بڑا گھر ہے۔"],
    },
    {
        name: "m",
        q: "Small shop/Shayan",
        a: ["یہ شایان کی چھوٹی دکان ہے۔"],
    },
];
