export default [
    {
        name: "a",
        qStart: "یہ گھر اس",
        qEnd: "بڑا ہے۔",
        a: ["گھر سے"],
    },
    {
        name: "b",
        qStart: "اس کے جوتے میرے",
        qEnd: "سے مہنگے ہیں۔",
        a: ["جوتوں"],
    },
    {
        name: "c",
        qStart: "یہ میری ",
        qEnd: "سے سستی کتاب ہے۔",
        a: ["کتاب"],
    },
    {
        name: "d",
        qStart: "یہ کتاب اس کتاب",
        qEnd: "مہنگی ہے۔",
        a: ["سے"],
    },
    {
        name: "e",
        qStart: "اردو",
        qEnd: "سے مشکل زبان ہے۔",
        a: ["جرمن"],
    },
    {
        name: "f",
        qStart: "یہ امتحان سب سے",
        qEnd: "ہے۔",
        a: ["مشکل"],
    },
    {
        name: "g",
        qStart: "یہ کپڑے",
        qEnd: "خوبصورت ہیں۔",
        a: ["اس سے"],
    },
    {
        name: "h",
        qStart: "یہ گھر اس",
        qEnd: "سے چھوٹا ہے۔",
        a: ["گھر"],
    },
];
