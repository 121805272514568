export default [
    {
        name: "a",
        q: "The baby is hungry. Please give him some milk.",
        a: ["بچے کو بھوک لگی ہے۔ اسے کچھ دودھ دیجیے۔"],
    },
    {
        name: "b",
        q: "The new film was (seemed) interesting to us.",
        a: ["ہمیں نئی فلم دلچسپ لگی۔"],
    },
    {
        name: "c",
        q: "A beautiful painting was hung on (attached to) the wall.",
        a: ["خوبصورت تصویر دیوار پر لگی تھی۔"],
    },
    {
        name: "d",
        q: "The little girl got hurt today.",
        a: ["آج چھوٹی لڑکی کو چوٹ لگی۔"],
    },
    {
        name: "e",
        q: "We thought (felt) the food was too spicy.",
        a: ["ہمیں کھانے میں تیز مرچیں لگیں۔"],
    },
    {
        name: "f",
        q: "Children often get scared at night.",
        a: ["بچوں کو اکثر رات میں ڈر لگتا ہے۔"],
    },
    {
        name: "g",
        q: "It took me three hours to write this essay.",
        a: ["یہ مضمون لکھنے میں مجھے تین گھنٹے لگے۔"],
    },
    {
        name: "h",
        q: "My brother likes to play the tabla.",
        a: ["میرے بھائی کو طبلہ بجانا اچھا لگتا ہے۔"],
    },
]
