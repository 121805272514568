const questions = [
    {
        "name": "a",
        "q": "I / a shopkeeper",
        "a": ["میں ایک دکاندار ہوں۔"],
        "dir": "ltr",
        "given": true,
    },
    {
        "name": "b",
        "q": "He / a student",
        "a": ["وہ طالب علم ہے۔"],
        "dir": "ltr",
    },
    {
        "name": "c",
        "q": "You (informal) / Indian",
        "a": ["تو ہندوستانی ہے۔"],
        "dir": "ltr",
    },
    {
        "name": "d",
        "q": "We / Chinese",
        "a": ["ہم چینی ہیں۔"],
        "dir": "ltr",
    },
    {
        "name": "e",
        "q": "You (polite) / from America",
        "a": ["آپ امریکا سے ہیں۔", "آپ امریکہ سے ہیں۔"],
        "dir": "ltr",
    },
    {
        "name": "f",
        "q": "She / from Karachi",
        "a": ["وہ کراچی سے ہے۔"],
        "dir": "ltr",
    },
    {
        "name": "g",
        "q": "He / from Islamabad",
        "a": ["وہ اسلام آباد سے ہے۔"],
        "dir": "ltr",
    },
    {
        "name": "h",
        "q": "We / all from Britain",
        "a": ["ہم سب برطانیہ سے ہیں۔"],
        "dir": "ltr",
    }, 
    {
        "name": "i",
        "q": "آپ / امریکی",
        "a": ["آپ امریکی ہیں۔"],
        "dir": "rtl",
        "given": true,
    },
    {
        "name": "j",
        "q": "آپ / اچھے",
        "a": ["آپ اچھے ہیں۔"],
        "dir": "rtl",
    },
    {
        "name": "k",
        "q": "وہ / خوبصورت",
        "a": ["وہ خوبصورت ہے۔"],
        "dir": "rtl",
    },
    {
        "name": "l",
        "q": "یہ / بدصورت",
        "a": ["یہ بد صورت ہے۔"],
        "dir": "rtl",
    },
    {
        "name": "m",
        "q": "ہم / لمبے",
        "a": ["ہم لمبے ہیں۔"],
        "dir": "rtl",
    },
    {
        "name": "n",
        "q": "وہ / خوش",
        "a": ["وہ خوش ہے۔"],
        "dir": "rtl",
    },
    {
        "name": "o",
        "q": "میں / خوش",
        "a": ["میں خوش ہوں۔"],
        "dir": "rtl",
    },
    {
        "name": "p",
        "q": "وہ / سفید",
        "a": ["وہ سفید ہے۔"],
        "dir": "rtl",
    },
    {
        "name": "q",
        "q": "وہ / خوبصورت اور اچھا",
        "a": ["وہ خوبصورت اور اچھا ہے۔"],
        "dir": "rtl",
    },
]

export default questions;