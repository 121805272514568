import React from "react";
import { imageRows, wordRows } from "./1-3-content.js";
import MatchingArrowsExercise from "../../../components/MatchingArrowsExercise.js";

export default function(props) {
    return (
        <MatchingArrowsExercise 
            title="1.3. Who is who? Match the pictures with the correct pronoun."
            subText="Click on a word, and then on its matching image."
            imageRows={imageRows}
            wordRows={wordRows}
            givenArrow={{start: 3, end: 4, direction: "rtl"}}
            reportProgress={props.reportProgress}
        />
    );
}
