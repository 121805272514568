export default [
    {
        name: "a",
        q: "Students ask questions.",
        a: ["طالب علم سوال پوچھتے ہیں۔"],
    },
    {
        name: "b",
        q: "I drink tea, but my friend drinks coffee.",
        a: [
            "میں چائے پیتا ہوں لیکن میرا دوست کافی پیتا ہے۔",
            "میں چائے پیتا ہوں لیکن میری دوست کافی پیتی ہے۔",
            "میں چائے پیتی ہوں لیکن میرا دوست کافی پیتا ہے۔",
            "میں چائے پیتی ہوں لیکن میری دوست کافی پیتی ہے۔",
        ],
    },
    {
        name: "c",
        q: "Many foreign students study here.",
        a: ["یہاں بہت سارے غیر ملکی طالب علم پڑھتے ہیں۔"],
    },
    {
        name: "d",
        q: "Pakistanis speak Urdu.",
        a: [
            "پاکستانی اردو بولتے ہیں۔", 
            "پاکستانی اُردو بولتے ہیں۔",
        ],
    },
    {
        name: "e",
        q: "He doesn’t understand French.",
        a: [
            "وہ فرانسیسی زبان نہیں سمجھتا۔", 
        ],
    },
    {
        name: "f",
        q: "Do you sell fresh vegetables?",
        a: [
            "کیا تم تازہ سبزیاں بیچتے ہو؟",
            "کیا آپ تازہ سبزیاں بیچتے ہیں؟",
        ],
    },
    {
        name: "g",
        q: "They live in Hyderabad but work in Karachi.",
        a: ["وہ حیدرآباد میں رہتے ہیں لیکن کراچی میں کام کرتے ہیں۔"],
    },
    {
        name: "h",
        q: "What do you study? I study economics.",
        a: [
            "آپ کیا پڑھتے ہیں؟ میں اکنامکس پڑھتا ہوں۔",
            "آپ کیا پڑھتی ہیں؟ میں اکنامکس پڑھتی ہوں۔",
        ],
    },
];
