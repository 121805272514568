import React from "react";
import questions from "./6-3-questions.js";
import LongBlanksExercise from "../../../components/LongBlanksExercise.js";

function exSixThree(props) {
    return (
        <LongBlanksExercise 
            title="6.3. Translate the following sentences."
            questions={questions}
            dir="ltr" 
            answerEnding="statement"
            reportProgress={props.reportProgress}
        />
    );
}

export default exSixThree;