export default [
    {
        name: "a",
        q: "وہ (m)",
        a: ["وہ دوست سے بات کر رہاہے۔"],
        given: true,
    },
    {
        name: "b",
        q: "وہ (f)",
        a: ["وہ دوست سے بات کر رہی ہے۔"],
    },
    {
        name: "c",
        q: "ہم",
        a: ["ہم دوست سے بات کر رہے ہیں۔"],
    },
    {
        name: "d",
        q: "یہ (pl)",
        a: ["یہ دوست سے بات کر رہے ہیں۔"],
    },
    {
        name: "e",
        q: "تم (f)",
        a: ["تم دوست سے بات کر رہی ہو۔"],
    },
    {
        name: "f",
        q: "یہ (f)",
        a: ["یہ دوست سے بات کر رہی ہے۔"],
    },
    {
        name: "g",
        q: "کون (sg)",
        a: ["کون دوست سے بات کر رہا ہے۔"],
    },
    {
        name: "h",
        q: "تم لوگ",
        a: ["تم لو گ دوست سے بات کر رہے ہو۔"],
    },
    {
        name: "i",
        q: "آپ (f)",
        a: ["آپ دوست سے بات کر رہی ہیں۔"],
    },
];
