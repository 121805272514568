import React from "react";
import questions from "./7-2-questions.js";
import LongBlanksExercise from "../../../components/LongBlanksExercise.js";

export default function exSevenTwo(props) {
    return (
        <LongBlanksExercise 
            title="7.2. Whose is it? Form questions using the below given words."
            questions={questions}
            reportProgress={props.reportProgress}
        />
    );
}
