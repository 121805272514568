export default [
    {
        name: "a",
        q: "(road, long)",
        a: ["راستہ لمبا ہے۔", "سڑک لمبی ہے۔"],
    },
    {
        name: "b",
        q: "(mountain, high)",
        a: ["پہاڑ اونچا ہے۔"],
    },
    {
        name: "c",
        q: "(desert, dry)",
        a: ["صحرا خشک ہے۔"],
    },
    {
        name: "d",
        q: "(tree, green)",
        a: ["درخت ہرا ہے۔"],
    },
    {
        name: "e",
        q: "(river, thin)",
        a: ["دریا پتلا ہے۔"],
    },
    {
        name: "f",
        q: "(house, old)",
        a: ["گھر پرانا ہے۔"],
    },
];