const questions = [
    {
        "name": "a",
        "q": "کمرا",        
        "a": ["کمرے"],
        "num": 9,
        given: true,
    },
    {
        "name": "b",
        "q": "بازار",
        "a": ["بازار"],
        "num": 10,
    },
    {
        "name": "c",
        "q": "لڑکی",
        "a": ["لڑکیاں"],
        "num": 2,
    },
    {
        "name": "d",
        "q": "سنترا",
        "a": ["سنترے"],
        "num": 7,
    },
    {
        "name": "e",
        "q": "لڑکا",
        "a": ["لڑکے"],
        "num": 8,
    },
    {
        "name": "f",
        "q": "کرسی",
        "a": ["کرسیاں"],
        "num": 3,
    },
    {
        "name": "g",
        "q": "قلم",
        "a": ["قلم"],
        "num": 5,
    },
    {
        "name": "h",
        "q": "جوتا",
        "a": ["جوتے"],
        "num": 4,
    },
    {
        "name": "i",
        "q": "کہانی",
        "a": ["کہانیاں"],
        "num": 5,
    },
    {
        "name": "j",
        "q": "استاد",
        "a": ["استاد", "اساتذہ"],
        "num": 8,
    },
    {
        "name": "k",
        "q": "کیلا",
        "a": ["کیلے"],
        "num": 2,
    },
    {
        "name": "l",
        "q": "ٹوپی",
        "a": ["ٹوپیاں"],
        "num": 2,
    },
];

export default questions;