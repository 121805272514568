import React from "react";
import questions from "./4-5-questions.js";
import LongBlanksExercise from "../../../components/LongBlanksExercise.js";
import village from "../../../images/village.jpg";

function exFourTwo(props) {
    return (
        <LongBlanksExercise 
            title="4.6. Describe the picture below using the nouns and adjectives given."
            questions={questions}
            image={{ src: village, alt: "A little farm-house beside the mountains"}}
            answerEnding="statement"
            reportProgress={props.reportProgress}
        />
    );
}

export default exFourTwo;