import yePic from '../../../images/yePic.png';
import mainPic from '../../../images/mainPic.png';
import wosubPic from '../../../images/wosubPic.png';
import humPic from '../../../images/humPic.png';
import aapPic from '../../../images/aapPic.png';
import tumPic from '../../../images/tumPic.png';
import aap2Pic from '../../../images/aap2Pic.png';

import yeW from '../../../images/ye.png';
import mainW from '../../../images/main.png';
import wosubW from '../../../images/wosub.png';
import humW from '../../../images/hum.png';
import aapW from '../../../images/aap.png';
import tumW from '../../../images/tum.png';


export const imageRows = [
    {src: aap2Pic, w:280, h:175, word: [1,6]}, 
    {src: tumPic, w:280, h:217, word: [4]},
    {src: aapPic, w:280, h:193, word: [1,6]}, 
    {src: mainPic, w:280, h:271, word: [0]},
    {src: yePic, w:280, h:184, word: [3]}, 
    {src: humPic, w:280, h:195, word: [2]}, 
    {src: wosubPic, w:280, h:195, word: [5]},
];
  
export const wordRows = [
    {src: mainW, w:80, h: 70}, 
    {src: aapW, w: 80, h: 74}, 
    {src: humW, w: 80, h: 96}, 
    {src: yeW, w: 160, h: 89}, 
    {src: tumW, w: 80, h: 100}, 
    {src: wosubW, w: 160, h: 79}, 
    {src: aapW, w: 80, h: 74},
];
