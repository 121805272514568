import React from "react";
import questions from "./6-4-questions.js";
import RadioExercise from "../../../components/RadioExercise.js";
import livingRoom from "../../../images/living-room.jpg";
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';


export default function exSixFour(props) {
    return (
        <RadioExercise 
            title="6.4. What is correct?"
            subText="Look at the picture and decide whether the sentences below are correct."
            image={{ src: livingRoom, alt: "A picture of a living room"}}
            questions={questions}
            options={[
                {label: <CheckIcon />, value: "y"},
                {label: <CloseIcon />, value: "n"},
            ]}
            reportProgress={props.reportProgress}
        />
    );
}
