export default [
    {
        name: "a",
        q: "used to live",
        a: ["وہ لڑکے یہاں رہتے تھے۔"],
        given: true,
    },
    {
        name: "b",
        q: "used to sleep",
        a: ["وہ لڑکے یہاں سوتے تھے۔"],
    },
    {
        name: "c",
        q: "used to work",
        a: ["وہ لڑکے یہاں کام کرتے تھے۔"],
    },
    {
        name: "d",
        q: "used to study",
        a: ["وہ لڑکے یہاں پڑھتے تھے۔"],
    },
    {
        name: "e",
        q: "used to sing",
        a: ["وہ لڑکے یہاں گاتے تھے۔"],
    },
    {
        name: "f",
        q: "used to eat",
        a: ["وہ لڑکے یہاں کھاتے تھے۔"],
    },
];
