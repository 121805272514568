import React from "react";
import questions from "./12-3-questions.js";
import LongBlanksExercise from "../../../components/LongBlanksExercise.js";

export default function exTwelveThree(props) {
    return (
        <LongBlanksExercise 
            title="Write questions using the word milna ملنا"
            questions={questions}
            dir="rtl"
            reportProgress={props.reportProgress}
        />
    );
}
