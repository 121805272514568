import React from "react";
import questions from "./17-2-questions.js";
import LongBlanksExercise from "../../../components/LongBlanksExercise.js";

function exSevenTwo(props) {
    return (
        <LongBlanksExercise 
            title="7.2. Change the sentences from the present into the future tense."
            questions={questions}
            dir="rtl"
            reportProgress={props.reportProgress}
        />
    );
}

export default exSevenTwo;
