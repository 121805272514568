import React from 'react';
import {
  FormControl,
  TextField,
  Button,
  Chip,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import styles from "../styles/exercise-styles.js";
import cleanUrdu from "../lib/clean-urdu.js";

function compareUrdu(answer, input) {
    const cleaned = cleanUrdu(input.trim());
    if (answer.includes(cleaned)) return true
    return false
}

class MiddleBlankExcercise extends React.Component {
  constructor(props) {
    super(props); 
    const questionsState = {};
    props.questions.forEach(question => {
      this[question.name + "Ref"] = React.createRef();
      questionsState[question.name] = { 
        wrong: false,
        corrected: false,
      };
    });
    this.state = {
      mistakes: 0,
      checked: false,
      ...questionsState,
    };
    this.handleChange = this.handleChange.bind(this);
    this.checkAnswers = this.checkAnswers.bind(this);
    this.clearAnswers = this.clearAnswers.bind(this);
    this.showOrHideAnswer = this.showOrHideAnswer.bind(this);

    this.classes = this.props.classes;
  }

  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({ [name]: value });
  }

  checkAnswers() {
    const toUpdate = { mistakes: 0, checked: true };
    // Go through and check each question
    this.props.questions.forEach(question => {
      // Remove marking in case of recheck
      toUpdate[question.name] = { wrong: false, corrected: this.state[question.name].corrected };
      // Check the question
      if (!compareUrdu(question.a, this[question.name + "Ref"].value)) {
        toUpdate.mistakes++;
        toUpdate[question.name] = { wrong: true, corrected: this.state[question.name].corrected }; 
      }
    });
    this.setState(toUpdate);
    this.props.reportProgress({ mistakes: toUpdate.mistakes, outof: this.props.questions.length });
  }

  clearAnswers() {
    const toSet = { 
      mistakes: 0, 
      checked: false,
    };
    this.props.questions.forEach(question => {
      if (!question.given) this[question.name + "Ref"].value = "";
      toSet[question.name] = { wrong: false, corrected: false };
    });
    this.setState(toSet);
  }

  showOrHideAnswer(name) {
    const o = this.props.questions.find(e => e.name === name)
    if (this.state[name].corrected) {
      // Hide answer if button is clicked again
      this[name + "Ref"].value = "";
    } else {
      this[name + "Ref"].value = o.a;
    }
    this.setState(prevState => ({
      [name]: { corrected: this.state[name].corrected ? false : true, wrong: prevState[name].wrong }
    }))
  }

  render() {
    return (
      <div style={{ margin: "0 auto", maxWidth: "700px" }}>
        <h2>{this.props.title}</h2>
        {this.props.subText && <p>{this.props.subText}</p>}
          {this.props.questions.map(question => (
          <div key={question.name}>
              <div className="question-container" style={{ direction: "rtl" }}>
                  <div className="short-question-field">
                      <span style={{ margin: "0 0.5rem" }}>{question.name}.</span> {question.qStart}
                  </div>
                  <div style={{ display: "flex", alignItems: "baseline", flexWrap: "wrap" }}>
                    <FormControl margin="dense" className="answer-field">
                        <TextField
                            id={question.name}
                            inputRef={input => this[question.name + "Ref"] = input}
                            InputProps={{
                                className: this.state[question.name].corrected ? this.classes.veryShortCorrectedInput : this.classes.veryShortInput,
                            }} 
                            autoComplete="off" 
                            name={question.name} 
                            defaultValue={question.given ? question.a[Math.round(Math.random())] : ""}
                            error={this.state[question.name].wrong}
                        />
                    </FormControl>
                  <div style={{ marginLeft: "1rem" }}>
                      {question.qEnd} 
                  </div>
                  {question.qParenth &&
                    <div>({question.qParenth})</div>
                  }
                  {this.state[question.name].wrong && 
                    <Button style={{ color: "grey" }} onClick={() => this.showOrHideAnswer(question.name)}>
                      {this.state[question.name].corrected ? "Hide" : "Answer"}
                    </Button>
                  }
              </div>
          </div>
        </div>
      ))}
      <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between", marginTop: "2rem" }}>
        <Button variant="contained" color="primary" onClick={this.checkAnswers} style={{ marginBottom: "1em" }}>
          {(this.state.checked && (this.state.mistakes !== 0)) ? "Recheck" : "Check"} Answers
        </Button>
        <div>
          {this.state.mistakes ? <Chip label={`${this.state.mistakes} mistakes`} color="secondary" style={{ marginBottom: "1em" }} /> : ''}  
          {(!this.state.mistakes && this.state.checked) && <Chip label={`All Correct!`} color="primary" />}
        </div>
        <Button varient="contained" color="primary" onClick={this.clearAnswers} style={{ marginBottom: "1em" }}>
          Clear Answers
        </Button>
      </div>
    </div>
    );
  }
}

export default withStyles(styles)(MiddleBlankExcercise);

