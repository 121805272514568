import React from "react";
import { imageRows, wordRows } from "./4-4-content.js";
import MatchingArrowsExercise from "../../../components/MatchingArrowsExercise.js";

export default function(props) {
    return (
        <MatchingArrowsExercise 
            title="4.4. How is it?"
            subText="Match the picture with the correct adjective. E.g. Match tea with hot."
            imageRows={imageRows}
            wordRows={wordRows}
            reportProgress={props.reportProgress}
        />
    );
}