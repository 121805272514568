export default [
    {
        name: "a",
        q: "These Urdu students are intelligent.",
        a: ["یہ اُردو کے طالب علم ہوشیار ہیں۔", "یہ اردو کے طالب علم ہوشیار ہیں۔"],
    },
    {
        name: "b",
        q: "What is your phone number?",
        a: ["آپ کا فون نمبر کیا ہے؟"],
    },
    {
        name: "c",
        q: "What is the price of the book?",
        a: ["کتاب کی قیمت کتنی ہے؟"],
    },
    {
        name: "d",
        q: "This is my pen. That is yours.",
        a: ["یہ میرا قلم ہے۔ وہ تمہارا ہے۔", "یہ میرا قلم ہے۔ وہ آپ کا ہے۔"],
    },
    {
        name: "e",
        q: "These are Sara’s pretty saris.",
        a: ["یہ خوبصورت ساڑھیاں سارہ کی ہیں۔"],
    },
    {
        name: "f",
        q: "Where are my papers? Your papers are on her table.",
        a: ["میرے کاغذات کہاں ہیں؟ آپ کے کاغذات اس کی میز پر ہیں۔"],
    },
    {
        name: "g",
        q: "Is this your correct answer?",
        a: ["کیا آپ کا یہ جواب صحیح ہے؟"],
    },
    {
        name: "h",
        q: "Our house is in the city. Where is your house?",
        a: ["ہمارا گھر شہر میں ہے۔ آپ کا گھر کہاں ہے؟", "ہمارا گھر شہر میں ہے۔ تمہارا گھر کہاں ہے؟"],
    },
    {
        name: "i",
        q: "Whose Urdu book is this? This is my Urdu book.",
        a: ["یہ اُردو کی کتاب کس کی ہے؟ یہ اُردو کی کتاب میری ہے۔"],
    },
];
