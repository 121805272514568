export default [
    {
        name: "a",
        q: "My room is bigger than your room.",
        a: ["میرا کمرہ تمہارے کمر ے سے بڑا ہے۔"],
    },
    {
        name: "b",
        q: "This book is better than the old book.",
        a: ["یہ کتاب ، پرانی کتاب سے بہتر ہے۔"],
    },
    {
        name: "c",
        q: "That intelligent student wrote the best essay.",
        a: ["اس ہوشیار طالب علم نے سب سے بہترین مضمون لکھا۔"],
    },
    {
        name: "d",
        q: "I am the youngest in my family. But my husband is the oldest in his family.",
        a: ["میں اپنے خاندان میں سب سے چھوٹی ہوں۔ لیکن میرا شوہر اپنے خاندان میں سب سے بڑا ہے۔"],
    },
    {
        name: "e",
        q: "This university is the best in America.",
        a: ["یہ یونیورسٹی امریکہ کی سب سے اچھی یونیورسٹی ہے۔"],
    },
    {
        name: "f",
        q: "Which country is the biggest in the world?",
        a: ["دنیا میں سب سے بڑا ملک کون سا ہے؟"],
    },
    {
        name: "g",
        q: "I am taller than my sister, but my brother is the tallest in the family.",
        a: ["میں اپنی بہن سے لمبی ہوں، لیکن میرا بھائی خاندان میں سب سے لمبا ہے۔"],
    },
];
