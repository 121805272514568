import React from "react";
import questions from "./12-2-questions.js";
import LongBlanksExercise from "../../../components/LongBlanksExercise.js";

export default function exTwelveTwo(props) {
    return (
        <LongBlanksExercise 
            title="Do you know…?"
            subText="Form sentences using the indirect verb constructions malum hai معلوم ہے."
            questions={questions}
            dir="rtl"
            reportProgress={props.reportProgress}
        />
    );
}
