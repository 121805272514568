export default [
    {
        name: "a",
        q: "آپ کو گھر جانا چاہیے اور خط لکھنا چاہیے۔",
        a: ["آپ کو گھر جا کر خط لکھنا چاہیے۔"],
        given: true,
    },
    {
        name: "b",
        q: "آپ کمرے میں جائیں اور لیٹیں۔",
        a: ["آپ کمرے میں جا کر لیٹیں۔"],
    },
    {
        name: "c",
        q: "وہ کتب خانے گیا اور کتاب لی۔",
        a: ["اس نے کتب خانے میں جا کر کتاب لی۔"],
    },
    {
        name: "d",
        q: "وہ دوست سے ملے گی اور بازار جائے گی۔",
        a: ["وہ دوست سے مل کر بازار جائے گی۔"],
    },
    {
        name: "e",
        q: "میں گھر جا رہی ہوں اور کھانا بناؤں گی۔",
        a: ["میں گھر جا کر کھانا بناؤں گی۔"],
    },
    {
        name: "f",
        q: "آپ اخبار پڑھیں اور  میز پر رکھیں۔",
        a: ["آپ اخبار پڑھ کر میز پر رکھیں۔"],
    },
    {
        name: "g",
        q: "وہ کھانا کھائے گا اور سوئے گا۔",
        a: ["وہ کھانا کھا کر سوئے گا۔"],
    },
    {
        name: "h",
        q: "علی یہاں آیا اور بیٹھا تھا۔",
        a: ["علی یہاں آ کر بیٹھا تھا۔"],
    },
]
