export default [
    {
        name: "a",
        q: "یہ سنترا اچھا ہے۔",
        a: ["یہ سنترے اچھے ہیں۔"],
        dir: "rtl",
    },
    {
        name: "b",
        q: "یہ مٹھائی اچھی ہے۔",
        a: ["یہ مٹھائیاں اچھی ہیں۔"],
    },
    {
        name: "c",
        q: "یہ طالب علم اچھا ہے۔",
        a: ["یہ طالب علم اچھے ہیں۔"],
    },
    {
        name: "d",
        q: "یہ قلم اچھا ہے۔",
        a: ["یہ قلم اچھے ہیں۔"],
    },
    {
        name: "e",
        q: "یہ کتاب دلچسپ ہے۔",
        a: ["یہ کتابیں دلچسپ ہیں۔"],
    },
];