import React from 'react';
/* eslint-disable no-unused-vars */
import { BrowserRouter as Router, withRouter } from "react-router-dom";
import ReactGA from 'react-ga';
ReactGA.initialize('UA-143238937-1');

class ScrollToTop extends React.Component {
    componentDidUpdate(prevProps) {
      if (this.props.location.pathname !== prevProps.location.pathname) {
        if (!this.props.location.hash) {
          window.scrollTo(0, 0);
          // Close the drawer if it's open
          if (this.props.drawerOpen) {
            this.props.closeDrawer();
          }
        }
        // Report pageview to Google Analytics
        ReactGA.pageview(this.props.location.pathname);
      }
    }
  
    render() {
      return this.props.children;
    }
}
  
export default withRouter(ScrollToTop);