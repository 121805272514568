import React from "react";
import questions from "./10-1-questions.js";
import LongBlanksExercise from "../../../components/LongBlanksExercise.js";

export default function exTenOne(props) {
    return (
        <LongBlanksExercise 
            title="Re-write the sentences using the “kar” کر construction."
            questions={questions}
            dir="rtl"
            reportProgress={props.reportProgress}
        />
    );
}
