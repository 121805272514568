import React from "react";
import questions from "./27-1-questions.js";
import LongBlanksExercise from "../../../components/LongBlanksExercise.js";

export default function exTwentySevenOne(props) {
    return (
        <LongBlanksExercise 
            title="27.1. Describe with لگنا"
            subText="Look at the pictures and write sentences using the expression lagna لگنا"
            questions={questions}
            imageQuestions
            reportProgress={props.reportProgress}
        />
    );
}
