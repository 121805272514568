export default [
    {
        name: "a",
        q: "Man/meat/cut",
        a: ["آدمی گوشت کاٹ رہا ہے۔"],
    },
    {
        name: "b",
        q: "Dog/run",
        a: ["کتا بھاگ رہا ہے۔"],
    },
    {
        name: "c",
        q: "2 women/cloth/buy",
        a: ["دوعورتیں کپڑے خرید رہی ہیں۔"],
    },
    {
        name: "d",
        q: "Shopkeeper/cloth/sell",
        a: ["دکاندار کپڑے بیچ رہا ہے۔"],
    },
    {
        name: "e",
        q: "Man/money/count",
        a: ["آدمی پیسے گن رہا ہے۔"],
    },
    {
        name: "f",
        q: "Boy/mango/sell",
        a: ["لڑکا آم بیچ رہا ہے۔"],
    },
    {
        name: "g",
        q: "Cat/meat/look at",
        a: ["بلی گوشت دیکھ رہی ہے۔"],
    },
    {
        name: "h",
        q: "Man/fruit/weigh",
        a: ["دکاندار پھل تول رہا ہے۔"],
    },
    {
        name: "i",
        q: "Man/dog/run",
        a: ["آدمی کتے کے پیچھے بھاگ رہا ہے۔"],
    },
];
