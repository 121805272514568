export default [
    {
        name: "a",
        q: "آپ / جواب",
        a: ["کیا آپ کو جواب معلوم ہے؟"],
        given: true,
    },
    {
        name: "b",
        q: "وہ / اس کا نام",
        a: [
            "کیا اُس کو اُس کا نام معلوم ہے؟", 
            "کیا اس کو اس کا نام معلوم ہے؟",
            "کیا اُس کو اس کا نام معلوم ہے؟",
            "کیا اس کو اُس کا نام معلوم ہے؟",
        ],
    },
    {
        name: "c",
        q: "میں / سوال",
        a: ["کیا مجھے سوال معلوم ہے؟"],
    },
    {
        name: "d",
        q: "وہ لوگ / اس شہر کا نام",
        a: ["کیا ان لوگوں کو اس شہر کا نام معلوم ہے؟"],
    },
    {
        name: "e",
        q: "تم / اس کتاب کا نام",
        a: ["کیا تمہیں اس کتاب کا نام معلوم ہے؟"],
    },
    {
        name: "f",
        q: "ہم / اس طالب علم کا نام",
        a: ["کیا ہمیں اس طالب علم کا نام معلوم ہے؟"],
    },
];
