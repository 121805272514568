export default [
    {
        name: "a",
        q: "کون - ہے - یہ",
        a: ["یہ کون ہے؟"],
    },
    {
        name: "b",
        q: "وہ - کیا - ہے",
        a: ["وہ کیا ہے؟"],
    },
    {
        name: "c",
        q: "آدمی - یہ - ہے - کیسا",
        a: ["یہ آدمی کیسا ہے؟"],
    },
    {
        name: "d",
        q: "ہیں - سے - آپ - کہاں",
        a: ["آپ کہاں سے ہیں؟"],
    },
    {
        name: "e",
        q: "نام - آپ - کا - ہے - کیا",
        a: ["آپ کا نام کیا ہے؟"],
    },
];