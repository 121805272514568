export default [
    {
        name: "a",
        q: "وہ اردو پڑھتا ہے۔",
        a: ["وہ اردو پڑھے گا۔", "وہ اُردو پڑھے گا۔"],
        given: true,
    },
    {
        name: "b",
        q: "کیا تم یہاں کام کرتے ہو؟",
        a: ["کیا تم یہاں کام کرو گے؟"],
    },
    {
        name: "c",
        q: "ہمارے استاد ہم سے سوال پوچھتے ہیں۔",
        a: ["ہمارے استاد ہم سے سوال پوچھیں گے۔"],
    },
    {
        name: "d",
        q: "کیا تمہارا دوست اردو پڑھتا ہے؟",
        a: ["کیا تمہارا دوست اردو پڑھے گا؟"],
    },
    {
        name: "e",
        q: "ہم لوگ ہندی فلمیں دیکھتے ہیں۔",
        a: ["ہم لوگ ہندی فلمیں دیکھیں گے۔"],
    },
    {
        name: "f",
        q: "آپ کا بھائی وہاں کیوں جاتا ہے؟",
        a: ["آپ کا بھائی وہاں کیوں جائے گا؟"],
    },
];
