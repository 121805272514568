export default [
    {
        name: "a",
        q: "وہ / جانا",
        a: ["وہ جاتا ہے۔", "وہ جاتی ہے۔"],
        given: true,
    },
    {
        name: "b",
        q: "میں / آنا",
        a: ["میں آتا ہوں۔", "میں آتی ہوں۔"],
    },
    {
        name: "c",
        q: "ہم / لانا",
        a: ["ہم لاتے ہیں۔"],
    },
    {
        name: "d",
        q: "تم / کھانا",
        a: ["تم کھاتے ہو۔", "تم کھاتی ہو۔"],
    },
    {
        name: "e",
        q: "آپ / اردو بولنا",
        a: ["آپ اردو بولتے ہیں۔", "آپ اردو بولتی ہیں۔", "آپ اُردو بولتے ہیں۔", "آپ اُردو بولتی ہیں۔"],
    },
    {
        name: "f",
        q: "میں / کتابیں خریدنا",
        a: ["میں کتابیں خریدتا ہوں۔", "میں کتابیں خریدتی ہوں۔"],
    },
    {
        name: "g",
        q: "یہ سب / فلم دیکھنا",
        a: ["یہ سب فلم دیکھتے ہیں۔"],
    },
    {
        name: "j",
        q: "آپ / خریدنا",
        a: ["آپ خریدتے ہیں۔", "آپ خریدتی ہیں۔"],
    },
    {
        name: "k",
        q: "میں / پڑھنا",
        a: ["میں پڑھتا ہوں۔", "میں پڑھتی ہوں۔"],
    },
    {
        name: "l",
        q: "یہ / دیکھنا",
        a: ["یہ دیکھتا ہے۔", "یہ دیکھتی ہے۔"],
    },
    {
        name: "m",
        q: "وہ دونوں سمجھنا",
        a: ["وہ دونوں سمجھتے ہیں۔", "وہ دونوں سمجھتی ہیں۔"],
    },
    {
        name: "n",
        q: "آپ / کام کرنا",
        a: ["آپ کام کرتے ہیں۔", "آپ کام کرتی ہیں۔"],
    },
    {
        name: "o",
        q: "وہ لڑکی / بازار جانا",
        a: ["وہ لڑکی بازار جاتی ہے۔"],
    },
    {
        name: "p",
        q: "ہم / اخبار پڑھنا",
        a: ["ہم اخبار پڑھتے ہیں۔"],
    },
];
