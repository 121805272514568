import baby from "../../../images/ex24-2/baby.png";
import bigGroup from "../../../images/ex24-2/big-group.png";
import buggy from "../../../images/ex24-2/buggy.png";
import burj from "../../../images/ex24-2/burj.png";
import car from "../../../images/ex24-2/car.png";
import eiffel from "../../../images/ex24-2/eiffel.png";
import house from "../../../images/ex24-2/house.png";
import oldMan from "../../../images/ex24-2/old-man.png";
import palace from "../../../images/ex24-2/palace.png";
import smallFamily from "../../../images/ex24-2/small-family.png";

export default [
    {
        name: "a",
        a: ["یہ گھر اس سے بڑا ہے۔"],
        hintText: "big",
        img: [
            { src: house, alt: "A house" },
            { src: palace, alt: "A palace" },
        ],
    },
    {
        name: "b",
        a: ["یہ گاڑی اس سے پرانی ہے۔"],
        hintText: "old",
        img: [
            { src: car, alt: "A car" },
            { src: buggy, alt: "A buggy" },
        ],
    },
    {
        name: "c",
        a: ["یہ خاندان اس سے چھوٹا ہے۔"],
        hintText: "small",
        img: [
            { src: bigGroup, alt: "A big group" },
            { src: smallFamily, alt: "A small family" },
        ],
    },
    {
        name: "d",
        a: ["یہ بچہ اس آدمی سے چھوٹا ہے۔"],
        hintText: "small",
        img: [
            { src: oldMan, alt: "An old man" },
            { src: baby, alt: "A baby" },
        ],
    },
    {
        name: "e",
        a: ["یہ مینار اس سے لمباہے۔"],
        hintText: "tall",
        img: [
            { src: eiffel, alt: "The Eiffel Tower" },
            { src: burj, alt: "The Burj Khalifa" },
        ],
    },
];
