export default [
    {
        name: "a",
        qStart: "یہ پھل، کپڑے، اور کرسیاں",
        qEnd: "ہیں۔",
        qParenth: "green",
        a: ["ہرے"],
    },
    {
        name: "b",
        qStart: "یہ کپڑا",
        qEnd: "ہے۔",
        qParenth: "expensive",
        a: ["مہنگا"],
    },
    {
        name: "c",
        qStart: "یہ کیلے",
        qEnd: "ہیں۔",
        qParenth: "sweet",
        a: ["میٹھے"],
    },
    {
        name: "d",
        qStart: "یہ کھانا",
        qEnd: "ہے۔",
        qParenth: "delicious",
        a: ["مزیدار"],
    },
    {
        name: "e",
        qStart: "یہ",
        qEnd: "کتاب ہے۔",
        qParenth: "thick",
        a: ["موٹی"],
    },
    {
        name: "f",
        qStart: "یہ کپڑا",
        qEnd: "ہے۔",
        qParenth: "beautiful",
        a: ["خوبصورت"],
    },
    {
        name: "g",
        qStart: "وہ ایک",
        qEnd: "لڑکی ہے۔",
        qParenth: "tall",
        a: ["لمبی"],
    },
    {
        name: "h",
        qStart: "یہ قمیضیں",
        qEnd: "ہیں۔",
        qParenth: "small",
        a: ["چھوٹی"],
    },
    {
        name: "i",
        qStart: "یہ کہانی",
        qEnd: "ہے۔",
        qParenth: "interesting",
        a: ["دلچسپ"],
    },
    {
        name: "j",
        qStart: "وہ",
        qEnd: "چوڑیاں ہیں۔",
        qParenth: "pink",
        a: ["گلابی"],
    },
];