export default [
    {
        name: "a",
        q: "Look/books",
        a: ["کتابیں دیکھیں۔"],
        given: true,
    },
    {
        name: "b",
        q: "Buy/clothes",
        a: ["کپڑے خریدیں۔"],
    },
    {
        name: "c",
        q: "Sell/computer",
        a: ["کمپیوٹر بیچیں۔"],
    },
    {
        name: "d",
        q: "Give/water",
        a: ["پانی دیں۔"],
    },
    {
        name: "e",
        q: "Read/newspaper",
        a: ["اخبار پڑھیں۔"],
    },
    {
        name: "f",
        q: "Take/cup",
        a: ["کپ لیں۔"],
    },    
]