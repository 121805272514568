import React from "react";
import questions from "./6-5-questions.js";
import LongBlanksExercise from "../../../components/LongBlanksExercise.js";
import kidOnRug from "../../../images/kid-on-rug.jpg";

export default function exSixFive(props) {
    return (
        <LongBlanksExercise 
            title="6.5. Now it’s your turn."
            subText="Describe where the following things and people are in the picture."
            image={{src: kidOnRug, alt: "A child standing on a rug in a room full of things"}}
            questions={questions}
            answerEnding="statement"
            reportProgress={props.reportProgress}
        />
    );
}
