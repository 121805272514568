import React from "react";
import questions from "./8-3-questions.js";
import LongBlanksExercise from "../../../components/LongBlanksExercise.js";

export default function exEightThree(props) {
    return (
        <LongBlanksExercise 
            title="8.3. Translate"
            questions={questions}
            answerEnding="statement"
            seperateAnswerLine
            reportProgress={props.reportProgress}
        />
    );
}
