// Chapter 1
import exOneOne from "./chapter-1/ex1-1/exerciseOneOne.js";
import exOneTwo from "./chapter-1/ex1-2/exerciseOneTwo.js";
import exOneThree from "./chapter-1/ex1-3/exerciseOneThree.js";
// Chapter 2
import exTwoOne from "./chapter-2/ex2-1/exerciseTwoOne.js";
import exTwoTwo from "./chapter-2/ex2-2/exerciseTwoTwo.js";
import exTwoThree from "./chapter-2/ex2-3/exerciseTwoThree.js";
import exTwoFour from "./chapter-2/ex2-4/exerciseTwoFour.js";
import exTwoFive from "./chapter-2/ex2-5/exerciseTwoFive.js";
// Chapter 3
import exThreeOne from "./chapter-3/ex3-1/exThreeOne.js";
import exThreeTwo from "./chapter-3/ex3-2/exThreeTwo.js";
import exThreeThree from "./chapter-3/ex3-3/exThreeThree.js";
import exThreeFour from "./chapter-3/ex3-4/exThreeFour.js";
// Chapter 4
import exFourOne from "./chapter-4/ex4-1/exFourOne.js";
import exFourTwo from "./chapter-4/ex4-2/exFourTwo.js";
import exFourThree from "./chapter-4/ex4-3/exFourThree.js";
import exFourFour from "./chapter-4/ex4-4/exFourFour.js";
import exFourFive from "./chapter-4/ex4-5/exFourFive.js";
// Chapter 5
import exFiveOne from "./chapter-5/ex5-1/exFiveOne.js";
import exFiveTwo from "./chapter-5/ex5-2/exFiveTwo.js";
// Chapter 6
import exSixOne from "./chapter-6/ex6-1/exSixOne.js";
import exSixTwo from "./chapter-6/ex6-2/exSixTwo.js";
import exSixThree from "./chapter-6/ex6-3/exSixThree.js";
import exSixFour from "./chapter-6/ex6-4/exSixFour.js";
import exSixFive from "./chapter-6/ex6-5/exSixFive.js";
// Chapter 7
import exSevenOne from "./chapter-7/ex7-1/exSevenOne.js";
import exSevenTwo from "./chapter-7/ex7-2/exSevenTwo.js";
import exSevenThree from "./chapter-7/ex7-3/exSevenThree.js";
import exSevenFour from "./chapter-7/ex7-4/exSevenFour.js";
// Chapter 8
import exEightOne from "./chapter-8/ex8-1/exEightOne.js";
import exEightTwo from "./chapter-8/ex8-2/exEightTwo.js";
import exEightThree from "./chapter-8/ex8-3/exEightThree.js";
import exEightFour from "./chapter-8/ex8-4/exEightFour.js";
// Chapter 10
import exTenOne from "./chapter-10/ex10-1/exTenOne.js";
import exTenTwo from "./chapter-10/ex10-2/exTenTwo.js";
import exTenThree from "./chapter-10/ex10-3/exTenThree.js";
import exTenFour from "./chapter-10/ex10-4/exTenFour.js";
// Chapter 11
import exElevenOne from "./chapter-11/ex11-1/exElevenOne.js";
import exElevenTwo from "./chapter-11/ex11-2/exElevenTwo.js";
import exElevenThree from "./chapter-11/ex11-3/exElevenThree.js";
import exElevenFour from "./chapter-11/ex11-4/exElevenFour.js";
// Chapter 12
import exTwelveOne from "./chapter-12/ex12-1/exTwelveOne.js";
import exTwelveTwo from "./chapter-12/ex12-2/exTwelveTwo.js";
import exTwelveThree from "./chapter-12/ex12-3/exTwelveThree.js";
import exTwelveFour from "./chapter-12/ex12-4/exTwelveFour.js";
import exTwelveFive from "./chapter-12/ex12-5/exTwelveFive.js";
// Chapter 15
import exFifteenOne from "./chapter-15/ex15-1/exFifteenOne.js";
import exFifteenTwo from "./chapter-15/ex15-2/exFifteenTwo.js";
import exFifteenThree from "./chapter-15/ex15-3/exFifteenThree.js";
import exFifteenFour from "./chapter-15/ex15-4/exFifteenFour.js";
// Chapter 17
import exSeventeenOne from "./chapter-17/ex17-1/exSeventeenOne.js";
import exSeventeenTwo from "./chapter-17/ex17-2/exSeventeenTwo.js";
import exSeventeenThree from "./chapter-17/ex17-3/exSeventeenThree.js";
import exSeventeenFour from "./chapter-17/ex17-4/exSeventeenFour.js";
// Chapter 24
import exTwentyFourOne from "./chapter-24/ex24-1/exTwentyFourOne.js";
import exTwentyFourTwo from "./chapter-24/ex24-2/exTwentyFourTwo.js";
import exTwentyFourThree from "./chapter-24/ex24-3/exTwentyFourThree.js";
// Chapter 27
import exTwentySevenOne from "./chapter-27/ex27-1/exTwentySevenOne.js";
import exTwentySevenTwo from "./chapter-27/ex27-2/exTwentySevenTwo.js";
import exTwentySevenThree from "./chapter-27/ex27-3/exTwentySevenThree.js";
import exTwentySevenFour from "./chapter-27/ex27-4/exTwentySevenFour.js";

let chapters = [
    {
        number: 1,
        free: true,
        heading: "PROUNOUNS AND THE VERB HONA ہونا",  
        exercises: [
            { component: exOneOne },
            { component: exOneTwo },
            { component: exOneThree },
        ],
    },
    {
        number: 2,
        heading: "Singular & Plural",
        free: true,  
        exercises: [
            { component: exTwoOne },
            { component: exTwoTwo },
            { component: exTwoThree },
            { component: exTwoFour },
            { component: exTwoFive },
        ],
    },
    {
        number: 3,
        heading: "Questions and Question Words",
        free: true,
        exercises: [
            { component: exThreeOne },
            { component: exThreeTwo },
            { component: exThreeThree },
            { component: exThreeFour },
        ],
    },
    {
        number: 4,
        heading: "Adjectives",
        free: true,
        exercises: [
            { component: exFourOne },
            { component: exFourTwo },
            { component: exFourThree },
            { component: exFourFour },
            { component: exFourFive },
        ],
    },
    {
        number: 5,
        heading: "Polite Requests & Informal Commands",
        free: true,
        exercises: [
            { component: exFiveOne },
            { component: exFiveTwo },
            // { component: exFiveThree } // Asked to be deleted
        ]
    },
    {
        number: 6,
        heading: "Postposition",
        free: true,
        exercises: [
            { component: exSixOne },
            { component: exSixTwo },
            { component: exSixThree },
            { component: exSixFour },
            { component: exSixFive },
        ],
    },
    {
        number: 7,
        heading: "Possessive Forms",
        free: true,
        exercises: [
            { component: exSevenOne },
            { component: exSevenTwo },
            { component: exSevenThree },
            { component: exSevenFour },
        ],
    },
    {
        number: 8,
        heading: "Talking about Routines (Present Simple Tense)",
        free: true,
        exercises: [
            { component: exEightOne },
            { component: exEightTwo },
            { component: exEightThree },
            { component: exEightFour },
        ],
    },
    {
        number: 10,
        heading: "The Conjunctive Particle (kar کر construction)",
        free: true,
        exercises: [
            { component: exTenOne },
            { component: exTenTwo },
            { component: exTenThree },
            { component: exTenFour },
        ],
    },
    {
        number: 11,
        heading: "Present Progressive Tense",
        free: true,
        exercises: [
            { component: exElevenOne },
            { component: exElevenTwo },
            { component: exElevenThree },
            { component: exElevenFour },
        ],
    },
    {
        number: 12,
        heading: "Impersonal Verb Constructions",
        free: true,
        exercises: [
            { component: exTwelveOne },
            { component: exTwelveTwo },
            { component: exTwelveThree },
            { component: exTwelveFour },
            { component: exTwelveFive },
        ],
    },
    {
        number: 15,
        heading: "The Past Habitual Tense",
        free: true,
        exercises: [
            { component: exFifteenOne },
            { component: exFifteenTwo },
            { component: exFifteenThree },
            { component: exFifteenFour },
        ],
    },
    {
        number: 17,
        heading: "The Future Tense",
        free: true,
        exercises: [
            { component: exSeventeenOne },
            { component: exSeventeenTwo },
            { component: exSeventeenThree },
            { component: exSeventeenFour },
        ],
    },
    {
        number: 24,
        heading: "Comparative and Superlative",
        free: true,
        exercises: [
            { component: exTwentyFourOne },
            { component: exTwentyFourTwo },
            { component: exTwentyFourThree },
        ],
    },
    {
        number: 27,
        heading: "Expression with Lagna لگنا",
        free: true,
        exercises: [
            { component: exTwentySevenOne },
            { component: exTwentySevenTwo },
            { component: exTwentySevenThree },
            { component: exTwentySevenFour },
        ],
    },
];

// Add title, slug and for and next to each chapter exercise
// First need to add the title an slug (so you can look ahead to them below)
chapters.forEach((chapter) => {
    chapter.title = `Chapter ${chapter.number}`
    chapter.link = `/chapter-${chapter.number}`;
    chapter.heading = `${chapter.title} ${chapter.heading}`;
    chapter.exercises.forEach((exercise, j) => {
        // Add title and slug
        exercise.title = `Exercise ${chapter.number}.${j+1}`;
        exercise.link = `/ex${chapter.number}-${j+1}`;
    });
});

chapters.forEach((chapter, i) => {
    chapter.exercises.forEach((exercise, j) => {
        // See if there's a next exercise
        let nextExercise = j === chapter.exercises.length - 1 ? null : chapter.exercises[j + 1];
        if (!nextExercise) {
            // No? maybe there's something a chapter ahead (if that exists) 
            nextExercise = i === chapters.length - 1 ? null : (chapters[i + 1].exercises && chapters[i + 1].exercises[0]);
        }
        if (nextExercise) {
            exercise.next = { link: nextExercise.link, title: nextExercise.title };
        }
        // See if there's a previous exercise
        let prevExercise = j === 0 ? null : chapter.exercises[j - 1];
        if (!prevExercise) {
            // No? maybe there's something a section behind (if that exists) 
            prevExercise = i === 0 ? null : chapters[i - 1].exercises[chapters[i - 1].exercises.length - 1];
        }
        if (prevExercise) {
            exercise.previous = { link: prevExercise.link, title: prevExercise.title };
        }
    });
});

export default chapters;