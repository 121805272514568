export default [
    {
        name: "a",
        q: "کیا آپ کراچی میں رہتے ہیں؟",
        a: [
            "جی ہاں میں کراچی میں رہتا ہوں۔", 
            "جی ہاں میں کراچی میں رہتی ہوں۔", 
            "جی نہیں میں کراچی میں نہیں رہتا ہوں۔",
            "جی نہیں میں کراچی میں نہیں رہتی ہوں۔",
        ],
        given: true,
    },
    {
        name: "b",
        q: "کیا آپ انگریزی بولتے ہیں؟",
        a: [
            "جی ہاں میں انگریزی بولتا ہوں۔", 
            "جی ہاں میں انگریزی بولتی ہوں۔",
            "جی نہیں میں انگریزی نہیں بولتا ہون۔",
            "جی نہیں میں انگریزی نہیں بولتی ہون۔",
        ],
    },
    {
        name: "c",
        q: "کیا آپ انگریزی سمجھتے ہیں؟",
        a: [
            "جی ہاں میں انگریزی سمجھتا ہوں۔", 
            "جی ہاں میں انگریزی سمجھتی ہوں۔",
            "جی نہیں میں انگریزی نہیں سمجھتا ہوں۔",
            "جی نہیں میں انگریزی نہیں سمجھتی ہوں۔",
        ],
    },
    {
        name: "d",
        q: "کیا آپ اردو پڑھتے ہیں؟",
        a: [
            "جی ہاں میں اردو پڑھتا ہوں۔", 
            "جی ہاں میں اردو پڑھتی ہوں۔",
            "جی نہیں میں اردو نہیں پڑھتا ہوں۔",
            "جی نہیں میں اردو نہیں پڑھتی ہوں۔",
        ],
    },
    {
        name: "e",
        q: "کیا آپ پھل کھاتے ہیں؟",
        a: [
            "جی ہاں میں پھل کھاتا ہوں۔", 
            "جی ہاں میں پھل کھاتی ہوں۔",
            "جی نہیں میں بھل نہیں کھاتا ہوں۔",
            "جی نہیں میں بھل نہیں کھاتی ہوں۔",
        ],
    },
    {
        name: "f",
        q: "کیا آپ بہت کتابیں خریدتے ہیں؟",
        a: [
            "جی ہاں میں بہت کتابیں خریدتا ہوں۔",
            "جی ہاں میں بہت کتابیں خریدتی ہوں۔",
            "جی نہیں میں بہت کتابیں نہیں خریدتا ہوں۔", 
            "جی نہیں میں بہت کتابیں نہیں خریدتی ہوں۔",
        ],
    },
];
