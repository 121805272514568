import React from "react";
import questions from "./5-2-questions.js";
import LongBlanksExercise from "../../../components/LongBlanksExercise.js";

function exFiveTwo(props) {
    return (
        <LongBlanksExercise 
            title="5.2. Make informal commands."
            questions={questions}
            dir="ltr" 
            answerEnding="statement"
            reportProgress={props.reportProgress}
        />
    );
}

export default exFiveTwo;