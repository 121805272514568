import React from "react";
import questions from "./11-3-questions.js";
import LongBlanksExercise from "../../../components/LongBlanksExercise.js";

export default function exElevenThree(props) {
    return (
        <LongBlanksExercise 
            title="Translate."
            questions={questions}
            seperateAnswerLine
            reportProgress={props.reportProgress}
        />
    );
}
