import React, { Component } from 'react';
import {
  FormControl,
  Button,
  Chip,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import styles from "../styles/exercise-styles.js";

class RadioExercise extends Component {
  constructor(props) {
    super(props); 
    const questionsState = {};
    props.questions.forEach(question => {
      questionsState[question.name] = {
        value: question.given ? question.a : "", 
        wrong: false,
        corrected: false,
      };
    });
    this.state = {
      mistakes: 0,
      checked: false,
      ...questionsState,
    };
    this.handleChange = this.handleChange.bind(this);
    this.checkAnswers = this.checkAnswers.bind(this);
    this.clearAnswers = this.clearAnswers.bind(this);
    this.showOrHideAnswer = this.showOrHideAnswer.bind(this);

    this.classes = this.props.classes;
  }

  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    const newQuestionInfo = { ...this.state[name], value, wrong: false };
    this.setState({ [name]: newQuestionInfo, mistakes: 0, checked: false });
  }

  checkAnswers() {
    const toUpdate = { mistakes: 0, checked: true };
    // Go through and check each question
    let thereWasAnEmptyAnswer = false;
    this.props.questions.forEach(question => {
      const value = this.state[question.name].value;
      if (value === "") {
        thereWasAnEmptyAnswer = true;
      }
      // Remove marking in case of recheck
      toUpdate[question.name] = { value: value, wrong: false, corrected: this.state[question.name].corrected };
      // Check the question
      if (question.a !== value) {
        toUpdate.mistakes++;
        toUpdate[question.name] = { value: value, wrong: true, corrected: this.state[question.name].corrected }; 
      }
    });
    if (thereWasAnEmptyAnswer) {
      alert("Please select an answer for every question");
      return;
    }
    this.setState(toUpdate);
    this.props.reportProgress({ mistakes: toUpdate.mistakes, outof: this.props.questions.length });
  }

  clearAnswers() {
    const toSet = { 
      mistakes: 0, 
      checked: false,
    };
    this.props.questions.forEach(question => {
      toSet[question.name] = { value: "", wrong: false, corrected: false };
    });
    this.setState(toSet);
  }

  showOrHideAnswer(name) {
    const o = this.props.questions.find(e => e.name === name)
    if (this.state[name].corrected) {
      // Hide answer if button is clicked again
      this[name + "Ref"].value = "";
    } else {
      // If clicked for the first time, show the answer
      this[name + "Ref"].value = o.num + " " + o.a;
    }
    this.setState(prevState => ({
      [name]: { corrected: this.state[name].corrected ? false : true, wrong: prevState[name].wrong }
    }))
  }

  render() {
    return (
      <div style={{ margin: "0 auto", maxWidth: "700px" }}>
        <h2>{this.props.title}</h2>
        {this.props.subText && <p>{this.props.subText}</p>}
        {this.props.image && <img src={this.props.image.src} alt={this.props.image.alt} />}
        {this.props.questions.map(question => (
          <div key={question.name} className="question-container" style={{ margin: "1rem 2rem", display: "flex", flexDirection: "row-reverse", flexWrap: "wrap" }}>
            <div className="question-field" style={{ direction: "rtl" }}>
              <span style={{ marginLeft: "0.5rem" }}>{question.name}. </span><span style={{ direction: "rtl" }}>{question.q}</span>
            </div>
            <FormControl className="answer-field">
              <RadioGroup aria-label={question.name} name={question.name} value={this.state[question.name].value} onChange={this.handleChange} row>
                <FormControlLabel
                  value={this.props.options[0].value}
                  control={<Radio color={this.state[question.name].wrong ? "secondary" : "primary"} />}
                  label={this.props.options[0].label}
                  labelPlacement="start"
                />
                <FormControlLabel
                  value={this.props.options[1].value}
                  control={<Radio color={this.state[question.name].wrong ? "secondary" : "primary"} />}
                  label={this.props.options[1].label}
                  labelPlacement="start"
                />
              </RadioGroup>
            </FormControl>
          </div>
        ))}
        <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between", marginTop: "2rem" }}>
          <Button variant="contained" color="primary" onClick={this.checkAnswers} style={{ marginBottom: "1em" }}>
            {(this.state.checked && (this.state.mistakes !== 0)) ? "Recheck" : "Check"} Answers
          </Button>
          <div>
            {this.state.mistakes ? <Chip label={`${this.state.mistakes} mistakes`} color="secondary" style={{ marginBottom: "1em" }} /> : ''}  
            {(!this.state.mistakes && this.state.checked) && <Chip label={`All Correct!`} color="primary" />}
          </div>
          <Button varient="contained" color="primary" onClick={this.clearAnswers} style={{ marginBottom: "1em" }}>
            Clear Answers
          </Button>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(RadioExercise);
