import React from "react";
import questions from "./8-1-questions.js";
import LongBlanksExercise from "../../../components/LongBlanksExercise.js";

export default function exEightOne(props) {
    return (
        <LongBlanksExercise 
            title="8.1. I eat, he reads, we work – and what do you do?"
            subText="Form sentences using the hints given."
            questions={questions}
            answerEnding="statement"
            reportProgress={props.reportProgress}
        />
    );
}
