export default [
    {
        name: "a",
        q: "They used to study in the library.",
        a: ["وہ کتب خانے میں پڑھتے تھے۔"],
    },
    {
        name: "b",
        q: "Last year I used to live in a big house near the university.",
        a: ["پچھلے سال میں یونیورسٹی کے نزدیک بڑے گھر میں رہتا تھا۔"],
    },
    {
        name: "c",
        q: "She used to buy books from this store.",
        a: ["وہ اس دکان سے کتابیں خریدتی تھی۔"],
    },
    {
        name: "d",
        q: "Who used to work here? My brother used to work here.",
        a: ["یہاں کون کام کرتا تھا؟ یہاں میرا بھائی کام کرتا تھا۔"],
    },
    {
        name: "e",
        q: "He used to ask the teacher many questions.",
        a: ["وہ استاد سے بہت سوال پوچھتا تھا۔"],
    },
    {
        name: "f",
        q: "They (f) used to buy clothes from this shop.",
        a: ["وہ اس دکان سے کپڑے خریدتی تھیں۔"],
    },
    {
        name: "g",
        q: "The children used to play in this park.",
        a: ["بچے اس پارک میں کھیلتے تھے۔"],
    },
    {
        name: "h",
        q: "John and I used to watch many Bollywood films.",
        a: ["جان اور میں بہت بولی دڈ فلمیں دیکھتے تھے۔"],
    },
    {
        name: "i",
        q: "Last year her brother used to work in this office.",
        a: ["پچھلے سال اس کا بھائی اس دفتر میں کام کرتا تھا۔"],
    },
];
