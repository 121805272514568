export default [
    {
        name: "a",
        q: "On the table",
        a: ["وہ کتابیں میز پر ہیں۔"],
        given: true,
    },
    {
        name: "b",
        q: "On the floor",
        a: ["وہ کتابیں فرش پر ہیں۔"],
    },
    {
        name: "c",
        q: "In the class",
        a: ["وہ کتابیں کلاس میں ہیں۔"],
    },
    {
        name: "d",
        q: "کے نیچے",
        a: ["کرسی کے نیچے کیا ہے؟"],
        dir: "rtl",
        given: true,
        answerEnding: "؟",
    },
    {
        name: "e",
        q: "کے اوپر",
        dir: "rtl",
        a: ["کرسی کے اوپر کیا ہے؟"],
        answerEnding: "؟",
    },
    {
        name: "f",
        q: "کے سامنے",
        dir: "rtl",
        a: ["کرسی کے سامنے کیا ہے؟"],
        answerEnding: "؟",
    },
    {
        name: "g",
        q: "کے پیچھے",
        dir: "rtl",
        a: ["کرسی کے پیچھے کیا ہے؟"],
        answerEnding: "؟",
    },
];